import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contact } from './contact.model';
import { ContactFormService } from './contact-form.service';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogContactConfirmation } from './dialog/dialog-contact-confirmation.service';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.css'],
})
export class FooterComponent implements OnInit, OnDestroy {
  contactListener: Subscription;
  hasSentContactForm = false;
  statusCode: number;
  statusCodeListener: Subscription;

  contactForm: NgForm;

  constructor(
    private contactService: ContactFormService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.router.events
      .pipe(
        // Filtrar solo los eventos de fin de navegación
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Desplazarse al inicio de la página
        window.scrollTo(0, 0);
      });
  }

  ngOnDestroy() {
    this.statusCodeListener.unsubscribe();
    this.contactListener.unsubscribe();
  }

  ngOnInit() {
    this.statusCodeListener = this.contactService
      .getStatusCodeListener()
      .subscribe((status) => {
        this.statusCode = status;
        if (this.statusCode === 200) {
          this.openDialog();
        }
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContactConfirmation);

    dialogRef.afterClosed().subscribe(() => {
      this.contactForm.resetForm();
    });
  }

  onContactForm(form: NgForm) {
    if (form.invalid) {
      return;
    }
    const contact: Contact = {
      names: form.value.names,
      email: form.value.email,
      message: form.value.message,
    };
    this.contactService.sendContactForm(contact);
    this.contactForm = form;
  }
}
