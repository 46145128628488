<div class="outter-center" *ngIf="isUserAuthenticated">

  <div class="inner-center">

    <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

    <mat-card class="main-card" *ngIf="!isLoading">

      <form [formGroup]="userFormGroup" (ngSubmit)="onSaveUser()" *ngIf="!isLoading">

<!--        {{userFormGroup.value | json}}-->

        <div class="container main-title">
          <h4><strong>EDITAR CUENTA</strong></h4>
          <div class="mat-divider-container">
            <mat-divider></mat-divider>
          </div>
        </div>


        <div class="container">
          <mat-card class="sub-field">
            <div class="container">
              <h5>Información personal</h5>
              <div class="mat-divider-container">
                <mat-divider></mat-divider>
              </div>
            </div>


            <div class="row container">
              <div class="col-md-6 grid-container">
                <div class="center-icon">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Nombres</mat-label>
                    <input matInput required type="text" formControlName="names">
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input matInput required type="text" formControlName="lastNames">
                </mat-form-field>
              </div>
            </div>


            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa fa-id-card-o" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>RUC</mat-label>
                    <input matInput type="text" formControlName="ruc">
                  </mat-form-field>
                </div>
              </div>
            </div>


            <div class="row container">
              <div class="col-md-5">
                <div>
                  <label id="example-radio-group-label">Sexo</label>
                </div>
                <mat-radio-group aria-label="Sexo" formControlName="sex">
                  <mat-radio-button value="mujer">Mujer</mat-radio-button>
                  <mat-radio-button value="hombre">Hombre</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="col-md-7 grid-container">
                <div class="center-icon">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Fecha de nacimiento (mm/dd/aaaa)</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="birthday">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

            </div>


            <div class="row container">

              <div class="col-md-6 grid-container">
                <div class="center-icon">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Teléfono principal</mat-label>
                    <input matInput required type="text" formControlName="phone1">
                  </mat-form-field>
                </div>
              </div>


              <div class="col-md-6">

                <div>
                  <mat-form-field>
                    <mat-label>Teléfono secundario (Opcional)</mat-label>
                    <input matInput  type="text" formControlName="phone2">
                  </mat-form-field>
                </div>

              </div>


            </div>


            <div class="row container">
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Provincia</mat-label>
                  <mat-select required formControlName="province">
                    <mat-option *ngFor="let p of provinceList; let i = index" [value]="p" (click)="getCities(i)">
                      {{p}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Ciudad</mat-label>
                  <mat-select required formControlName="city">
                    <mat-option *ngFor="let c of citiesList; let i = index" [value]="c">
                      {{c}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </div>

            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa  fa-map-marker" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input matInput type="text" formControlName="address">
                  </mat-form-field>
                </div>
              </div>
            </div>


          </mat-card>
        </div>


        <div class="container">
          <mat-card class="sub-field">
            <div class="container">
              <h5>Datos de la cuenta</h5>
              <div class="mat-divider-container">
                <mat-divider></mat-divider>
              </div>
            </div>


            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa  fa-envelope" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>{{userEmail}}</mat-label>
                    <input matInput disabled type="email">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="container row">
              <div class="col-md-12 passw-recover">
                <a mat-button (click)="onRestorePassword()" color="accent"><h6>Restablecer contraseña</h6></a>
              </div>
            </div>


          </mat-card>
        </div>


        <div class="flex-box container">
          <button mat-raised-button color="accent" type="submit"><strong>Guardar cambios</strong></button>
        </div>

      </form>


    </mat-card>

  </div>

</div>
