<div class="container">
  <section class="mat-typography title-group">
      <span class="title-group">
        <a mat-button routerLink="/" routerLinkActive="mat-accent"><h2>Home</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">

      <li class="title-group">
        <a mat-button routerLink="/productos/{{category}}" routerLinkActive="mat-accent"><h3>{{category}}</h3></a>
      </li>

      <li class="title-group">
        <a mat-button routerLink="/productos/{{category}}/{{productId}}" routerLinkActive="mat-accent">
          <h3>{{product?.name}}</h3></a>
      </li>

    </ul>
    <mat-divider></mat-divider>
  </section>

  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>


  <mat-card class="main-container" *ngIf="!isLoading">


    <div class="row">
      <div class="col-md-8 img-gallery">
        <mat-card>
          <div *ngIf="mainImagePath; else elseVideoBlock">
            <div class="img-container">
              <img [src]="mainImagePath" class="img-fluid">
            </div>
          </div>
          <ng-template #elseVideoBlock>
            <div *ngIf="hasVideo">


              <div [innerHTML]="iframeTemplate | safe: 'html'"></div>


              <!--                <iframe width="650"-->
              <!--                        height="500" [src]="getSafeUrl()"-->
              <!--                        frameborder="0"-->
              <!--                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
              <!--                        allowfullscreen></iframe>-->


            </div>
          </ng-template>
          <div class="thumbnail-container">
            <div class="hover-mark">
              <a (click)="onChangeMainImage(product.image1Path)"><img [src]="product?.image1Path" class="img-thumbnail"></a>
            </div>
            <div class="hover-mark" *ngIf="!!product.image2Path">
              <a (click)="onChangeMainImage(product.image2Path)"><img [src]="product?.image2Path" class="img-thumbnail"></a>
            </div>

            <div class="hover-mark" *ngIf="!!product.image3Path">
              <a (click)="onChangeMainImage(product.image3Path)"><img [src]="product?.image3Path" class="img-thumbnail"></a>
            </div>

            <div class="hover-mark" *ngIf="!!product.image4Path">
              <a (click)="onChangeMainImage(product.image4Path)"><img [src]="product?.image4Path" class="img-thumbnail"></a>
            </div>

            <div class="hover-mark" *ngIf="!!product.image5Path">
              <a (click)="onChangeMainImage(product.image5Path)"><img [src]="product?.image5Path" class="img-thumbnail"></a>
            </div>

            <div class="hover-mark" *ngIf="!!product.image6Path">
              <a (click)="onChangeMainImage(product.image6Path)"><img [src]="product?.image6Path" class="img-thumbnail"></a>
            </div>

            <div *ngIf="hasVideo" class="icon-container">
              <a (click)="onChangeMainImage('video')">
                <mat-icon>play_circle_outline</mat-icon>
              </a>
            </div>
          </div>
        </mat-card>
      </div>


      <div class="col-md-4 general-info">

        <form [formGroup]="orderForm" (ngSubmit)="onAddItem()">

          <!--            {{orderForm.value | json }}-->
          <!--            {{userId}}-->

<!--          MAT CARD FOR THE NAME-->
          <mat-card>
            <div>
              <h3>{{product?.name}}</h3>
            </div>
            <div class=" mat-divider-container">
              <mat-divider></mat-divider>
            </div>
          </mat-card>

          <mat-card>
            <h5>Presentaciones</h5>
            <div class=" mat-divider-container">
              <mat-divider></mat-divider>
            </div>

            <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="presentation"
              [(ngModel)]="selectedPresentation"
              [ngModelOptions]="{standalone: true}"
            >
              <div *ngIf="productPresentations['unidades'].length > 0">
                <h6>Por unidades</h6>
                <mat-radio-button class="example-radio-button"
                                  *ngFor="let presentation of productPresentations['unidades']" [value]="presentation">
                  {{presentation.unitsQuantity.toLowerCase() === 'unidad' ? '1 unidad' : presentation.unitsQuantity.toLowerCase()}}
                </mat-radio-button>
              </div>

              <div *ngIf="productPresentations['peso'].length > 0">
                <h6>Por peso</h6>
                <mat-radio-button class="example-radio-button" *ngFor="let presentation of productPresentations['peso']"
                                  [value]="presentation">
                  {{presentation.unitsQuantity.toLowerCase()}}
                </mat-radio-button>
              </div>

              <div *ngIf="productPresentations['juego'].length > 0">
                <h6>Por juegos</h6>
                <mat-radio-button class="example-radio-button"
                                  *ngFor="let presentation of productPresentations['juego']" [value]="presentation">
                  {{presentation.unitsQuantity.toLowerCase()}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
            <div *ngIf="!selectedPresentation">
              <h6 style="color: #ffffff; background: #E91E63"><strong>Seleccione una presentación</strong></h6>
            </div>

          </mat-card>


          <mat-card>
          <h5>Precio mayorista</h5>
          <div class=" mat-divider-container">
            <mat-divider></mat-divider>
            <h5>{{selectedPresentation?.price ? "$ " + selectedPresentation?.price : ""}}</h5>
          </div>
          </mat-card>



<!--          <mat-card>-->
<!--            <h5>Adquirir</h5>-->
<!--            <div class=" mat-divider-container">-->
<!--              <mat-divider></mat-divider>-->
<!--            </div>-->

<!--            <div *ngIf="isAvailable; else isNotAvailableBlock">-->
<!--              <h5 style="color: #1c7430">Disponible</h5>-->
<!--            </div>-->
<!--            <ng-template #isNotAvailableBlock>-->
<!--              <h5 style="color: #b60707">No disponible</h5>-->
<!--            </ng-template>-->


<!--            &lt;!&ndash;              <h4><strong>$ {{selectedPresentation?.price}}</strong></h4>&ndash;&gt;-->
<!--            &lt;!&ndash;              <h6 style="color: #b63413">PVP: $ {{selectedPresentation?.pvpPrice}}</h6>&ndash;&gt;-->


<!--            <div *ngIf="selectedPresentation; else elsePriceBlock">-->
<!--              <div *ngIf="units === 'unidades'; else elseUnitsBlock">-->
<!--                <h4><strong>$ {{selectedPresentation.price}}</strong></h4>-->
<!--                <h6 style="color: #b63413">PVP: $ {{selectedPresentation.pvpPrice}}</h6>-->
<!--              </div>-->
<!--              <ng-template #elseUnitsBlock>-->
<!--                <h4><strong>$ {{selectedPresentation.price}}</strong></h4>-->
<!--                <h6 style="color: #b63413">PVP: $ {{selectedPresentation.pvpPrice}}</h6>-->
<!--              </ng-template>-->
<!--            </div>-->
<!--            <mat-form-field appearance="fill">-->
<!--              <mat-label>Cantidad a adquirir</mat-label>-->
<!--              <input class=""-->
<!--                     [ngClass]="{'is-invalid': orderForm.get('quantity').errors && orderForm.get('quantity').touched}"-->
<!--                     matInput-->
<!--                     type="number"-->
<!--                     min=0-->
<!--                     formControlName="quantity">-->
<!--              <div class="invalid-feedback" *ngIf="orderForm.get('quantity').errors">-->
<!--                La cantidad debe ser entera positiva.-->
<!--              </div>-->
<!--            </mat-form-field>-->
<!--            -->
<!--            <div class="button-container" *ngIf="isClientAuthenticated; else elseButtonCart">-->
<!--              <button mat-raised-button type="submit" color="accent"><strong>Agregar al carrito</strong>-->
<!--              </button>-->
<!--            </div>-->
<!--            -->
<!--            <ng-template #elseButtonCart>-->
<!--              <div class="button-container">-->
<!--                <button mat-raised-button color="accent" type="submit"><strong>Agregar al carrito</strong>-->
<!--                </button>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </mat-card>-->

        </form>

      </div>
    </div>


    <div class="row add-top-margin">
      <div class="col-md-5">
        <mat-card>
          <h5>Información del producto</h5>
          <div class=" mat-divider-container">
            <mat-divider></mat-divider>
          </div>
          <table class="table">
            <tbody>

            <tr>
              <td class="cell-table">Referencia</td>
              <td>
                <div *ngIf="units === 'unidades'; else elseDimBlock">
                  {{selectedPresentation?.reference}}
                </div>
                <ng-template #elseDimBlock>
                  {{selectedPresentation?.reference}}
                </ng-template>
              </td>
            </tr>

            <tr>
              <td class="cell-table">Código de barras</td>
              <td>
                <div *ngIf="units === 'unidades'; else elseDimBlock">
                  {{selectedPresentation?.barCode}}
                </div>
                <ng-template #elseDimBlock>
                  {{selectedPresentation?.barCode}}
                </ng-template>
              </td>
            </tr>

            <tr>
              <td class="cell-table">
                Dimensiones del paquete
              </td>
              <td>
                <div *ngIf="units === 'unidades'; else elseDimBlock">
                  {{selectedPresentation?.large}}
                  x {{selectedPresentation?.width}}
                  x {{selectedPresentation?.depth}} cm
                </div>
                <ng-template #elseDimBlock>
                  {{selectedPresentation?.large}}
                  x {{selectedPresentation?.width}}
                  x {{selectedPresentation?.depth}} cm
                </ng-template>
              </td>
            </tr>
            <tr>
              <td class="cell-table">Peso del paquete</td>
              <td>
                <div *ngIf="units === 'unidades'; else elseWeightBlock">
                  {{selectedPresentation?.weight}} gr
                </div>
                <ng-template #elseWeightBlock>
                  {{selectedPresentation?.weight}} gr
                </ng-template>
              </td>
            </tr>

            <tr>
              <td class="cell-table">Cantidad en caja master</td>
              <td>
                <div *ngIf="units === 'unidades'; else elseDimBlock">
                  {{selectedPresentation?.masterBox}}
                </div>
                <ng-template #elseDimBlock>
                  {{selectedPresentation?.masterBox}}
                </ng-template>
              </td>
            </tr>

            </tbody>
          </table>
        </mat-card>

      </div>

      <div class="col-md-7">
        <mat-card class="height-filler">
          <h5>Descripción del producto</h5>
          <div class=" mat-divider-container">
            <mat-divider></mat-divider>
          </div>
          <h6>{{product?.description}}</h6>
        </mat-card>

      </div>
    </div>

  </mat-card>

</div>
