import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AuthClientService} from "../auth.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'activation-component',
  templateUrl: './activation-account.component.html',
  styleUrls: ['./activation-account.component.css']
})

export class ActivationAccountComponent implements OnInit, OnDestroy{
  title: string;
  body: string;

  isLoading = true;
  token: string;

  statusCode: number;
  statusCodeSub: Subscription;

  constructor(private _route: ActivatedRoute, private authService: AuthClientService) { }

  ngOnDestroy() {
    this.statusCodeSub.unsubscribe()
  }

  ngOnInit() {

    window.scroll(0, 0)

    this._route.paramMap.subscribe((paramMap) => {
      if (paramMap.has('token')) {
        this.token = paramMap.get('token');
      }
    })


    this.statusCodeSub = this.authService.getCodeStatusListener()
      .subscribe((status) => {
        this.statusCode = status;

        if(this.statusCode === 200){
          this.title = 'CUENTA ACTIVADA'
        } else {
          this.title = 'ERROR'
          this.body = 'Enlace expirado. Realice el registro nuevamente.'
        }
      })

    this.authService.activateAccount(this.token)

    this.isLoading = false;


  }

}
