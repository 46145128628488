import {Component, OnDestroy, OnInit} from "@angular/core";
import {Product} from "../../../../../src/app/products/product.model";
import {Subscription} from "rxjs";
import {ActivatedRoute, Route, Router, RouteReuseStrategy} from "@angular/router";
import {MINIMUM_PURCHASE_VALUE, POLITICS_VALUE_PURCHASE_MESSAGE_ON_CART} from "../../constants";
import {ShoppingCarService} from "./shopping-car.service";
import {ShoppingCar} from "./shopping-car.model";
import {AuthClientService} from "../auth/auth.service";
import {DialogAskOrderConfirmation} from "./dialogs/ask-confirmation/dialog-ask-confirmation.service";
import {DialogShowOrderConfirmation} from "./dialogs/show-confirmation/dialog-show-confirmation.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogEditUserConfirmation} from "../edit-user/dialog/dialog-edit-user-confirmation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'shopping-car-component',
  templateUrl: './shopping-car.component.html',
  styleUrls: ['./shopping-car.component.css']
})
export class ShoppingCarComponent implements OnInit, OnDestroy{
  userId: string;
  category: string;
  isLoading: boolean;
  userDiscount: number;
  iva: number;
  discount: number;
  total: number;
  taxes: number;
  totalProducts = 0;
  subtotal: number;
  subsubtotal: number;
  shoppingCar: ShoppingCar;
  shoppingCarId: string;
  products: Product[];
  extraInformation: string;
  politicsMessage: string = POLITICS_VALUE_PURCHASE_MESSAGE_ON_CART;
  minimumPurchaseValue: number = MINIMUM_PURCHASE_VALUE;


  private shoppingCarSub: Subscription;

  statusCode: number;
  statusCodeSub: Subscription;
  orderForm: FormGroup;


  constructor(private route: Router, private shoppingCarService: ShoppingCarService,
              private authClienteService: AuthClientService, private dialog: MatDialog,
              private formBuilder: FormBuilder,
              ) {}

  ngOnInit() {
    window.scrollTo(0, 0)
    this.isLoading = true;
    this.userId = this.authClienteService.userId;

    this.shoppingCarService.getShoppingCar(this.userId);

    this.orderForm = this.formBuilder.group({
      extraInformation: ["", []],
      }
    );


    this.shoppingCarSub = this.shoppingCarService.getShoppingCarUpdateListener()
      .subscribe((responseData) => {

        this.shoppingCar = responseData.shoppingCar;

        if(responseData.shoppingCar){
          this.shoppingCarId = responseData.shoppingCar._id;
          this.subtotal = Math.round((responseData.subtotal + Number.EPSILON) * 100) / 100
          this.subsubtotal = Math.round((responseData.subsubtotal + Number.EPSILON) * 100) / 100
          this.total = Math.round((responseData.total + Number.EPSILON) * 100) / 100
          this.iva = responseData.iva
          this.taxes = Math.round((responseData.taxes + Number.EPSILON) * 100) / 100
          this.discount = Math.round((responseData.discount + Number.EPSILON) * 100) / 100
          this.userDiscount = responseData.shoppingCar.userDiscount
        }

        this.isLoading = false;

      })

    this.statusCodeSub = this.shoppingCarService.getStatusCodeListener()
      .subscribe((status) => {
        this.statusCode = status;
        if(this.statusCode === 200 ){
          this.openDialogConfirmationOrder()
          this.statusCode = 0
        }
      })

  }

  ngOnDestroy() {
    this.statusCodeSub.unsubscribe();
    this.shoppingCarSub.unsubscribe();
  }

  openDialogConfirmationOrder(){
    const dialogRef = this.dialog.open(DialogShowOrderConfirmation);
    dialogRef.afterClosed().subscribe(() => {
      this.route.navigate(['/'])
    });
  }

  onDeleteItem(index){
    this.shoppingCarService.deleteItem(index, this.shoppingCar.carItems[index]._id);
  }

  openDialogAskConfirmation(){
    const dialogRef = this.dialog.open(DialogAskOrderConfirmation);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true){
        this.onPlaceOrder()
      }
    });
  }

  onPlaceOrder(){
    if(this.shoppingCarId){
      this.shoppingCarService.placeOrder(this.shoppingCarId, this.orderForm.value.extraInformation)
    }
  }


}


