import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthClientService} from "./auth.service";
import {Observable} from "rxjs";

@Injectable()
export class AuthClientInterceptor implements HttpInterceptor{
  constructor(private authClientService: AuthClientService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authClientService.getToken();
    const authRequest = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken),
    })
    return next.handle(authRequest)
  }
}


