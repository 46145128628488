import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthClientService } from '../auth/auth.service';
import { HeaderServices } from './header.services';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Category } from '../../../../../src/app/category/category.model';
import { CategoryService } from '../../../../../src/app/category/category.service';
import { ProformaService } from '../proforma/proforma.service';
import { DialogVideoTutorial } from './video-dialog/dialog-video-tutorial';
import { MatDialog } from '@angular/material/dialog';

const dataTransform = (data) => {
  let newData = {};
  for (let i = 0; i < data.length; i++) {
    newData[data[i]._id] = data[i];
  }
  return newData;
};

@Component({
  selector: 'header-client-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  productsCategories: Category[];
  newProductsCategories: {};
  private categoriesSub: Subscription;

  authListenerSub: Subscription;
  searchBarListenerSub: Subscription;
  proformaListenerSub: Subscription;
  isClientAuthenticated: boolean;
  username: string;
  options = ['One', 'Two', 'Three'];

  displayTutorial = false;

  userId: string;

  productsList: [] = [];
  productsListNames: any[] = [];

  productSelectedId: string;

  productSelectedCategory: string;

  proformaExists: boolean;
  proforma: any;

  constructor(
    private authClientService: AuthClientService,
    private searchBarService: HeaderServices,
    private router: Router,
    private categoriesService: CategoryService,
    private proformaService: ProformaService,
    private dialogVideoTutorial: MatDialog
  ) {}

  OnDestroy() {
    this.authListenerSub.unsubscribe();
    this.searchBarListenerSub.unsubscribe();
  }

  ngOnInit() {
    this.categoriesService.getCategories();
    this.categoriesSub = this.categoriesService
      .getCategoryUpdateListener()
      .subscribe((categoriesData: { categories: Category[] }) => {
        this.productsCategories = categoriesData.categories.filter(
          (c) => c.available
        );
        this.newProductsCategories = dataTransform(this.productsCategories);
      });

    this.proformaListenerSub = this.proformaService
      .getProformaExistsListener()
      .subscribe((existsData: { exists: boolean }) => {
        this.proformaExists = existsData.exists;
      });
    this.proforma = this.proformaService.getProformaData();

    this.authClientService.autoAuthUser();

    this.isClientAuthenticated = this.authClientService.getIsLogged();

    this.authListenerSub = this.authClientService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.isClientAuthenticated = isAuthenticated;
        this.username = this.authClientService.getUsername();
        this.userId = this.authClientService.userId;
      });

    this.isClientAuthenticated = this.authClientService.getIsLogged();
    this.username = this.authClientService.username;

    this.searchBarService.getListOfProductsToSearch();

    this.searchBarListenerSub = this.searchBarService
      .getProductsListListener()
      .subscribe((respData) => {
        this.productsList = respData.productsList;

        for (let i = 0; i < this.productsList.length; i++) {
          this.productsListNames.push({
            name: this.productsList[i]['product'],
            references: this.productsList[i]['references'],
          });
        }
      });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value): string[] {
    if (value.name) {
      return;
    }

    if (isNaN(value)) {
      const filterValue = value.toLowerCase();
      return this.productsListNames.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    } else {
      let auxList = [];

      for (let i = 0; i < this.productsListNames.length; i++) {
        for (let j = 0; j < this.productsListNames[i].references.length; j++) {
          if (
            this.productsListNames[i].references[j]
              .toString()
              .includes(value.toString())
          ) {
            auxList.push(this.productsListNames[i]);
          }
        }
      }

      const uniqueArray = auxList.filter((value, index) => {
        const _value = JSON.stringify(value.name);
        return (
          index ===
          auxList.findIndex((obj) => {
            return JSON.stringify(obj.name) === _value;
          })
        );
      });

      return uniqueArray;
      // return  auxList
      //
      // return this.productsListNames.filter(option => option.references.filter(ref => ref.includes(value.toString())));

      // return this.productsListNames.filter(option => option.reference.toLowerCase().includes(value));
    }
  }

  ngOnDestroy() {
    this.authListenerSub.unsubscribe();
  }
  onLogout() {
    this.authClientService.logOutUser();
  }

  onDisplayTutorial() {
    const dialogRef = this.dialogVideoTutorial.open(DialogVideoTutorial);
  }

  searchProduct(searchInput) {
    let index = 0;
    for (let i = 0; i < this.productsListNames.length; i++) {
      if (searchInput.name == this.productsListNames[i].name) {
        index = i;
      }
    }

    this.productSelectedId = this.productsList[index]['_id'];
    this.productSelectedCategory = this.productsList[index]['category'];

    this.router
      .navigate(
        [
          '/productos/' +
            this.newProductsCategories[this.productSelectedCategory].name +
            '/' +
            this.productSelectedCategory,
        ],
        { queryParams: { productId: this.productSelectedId } }
      )
      .then(() => {
        window.location.reload();
      });

    this.myControl.setValue('');
  }

  onEditAccount() {
    this.router.navigate(['/editar']);
  }
}
