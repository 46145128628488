import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ProformaService} from "./proforma.service";
import {ShoppingCarService} from "../shopping-car/shopping-car.service";
import {Subscription} from "rxjs";
import {IVA, MINIMUM_PURCHASE_VALUE, POLITICS_VALUE_PURCHASE_ON_PROFORMA} from "../../constants";
import {AuthClientService} from "../auth/auth.service";



const getProformaItems = (data) => {
  let items = []
  for (let key of Object.keys(data)){
    const {category, categoryId, imageUrl, pvpPrice, price, ...mainAttributes} = data[key]
    items.push(mainAttributes)
  }
  return items
}

const transformData = (data) => {
  let newList = [];
  let auxObject = {}
  for(let key of Object.keys(data)){
    if(!auxObject[data[key].category]){
      auxObject[data[key].category] = []
    }
    auxObject[data[key].category].push(data[key])
  }
  for(let key of Object.keys(auxObject)){
    newList.push({category: key, items: auxObject[key]})
  }
  return newList
}

const getSubtotal = (data) => {
  let ac = 0
  for(let key of Object.keys(data)){
    ac += parseFloat(data[key].price) * parseInt(data[key].quantity)
  }
  return Math.round(( ac + Number.EPSILON) * 100) / 100
}

const getPercentaje = (value, discount) => {
  return Math.round(( (value * (discount / 100)) + Number.EPSILON) * 100) / 100
}

const getSubstraction = (n1, n2) => {
  return Math.round(( (n1 - n2) + Number.EPSILON) * 100) / 100
}
const getAddition = (n1, n2) => {
  return Math.round(( (n1 + n2) + Number.EPSILON) * 100) / 100
}

@Component({
  selector: 'proforma',
  templateUrl: './proforma.component.html',
  styleUrls: ['./proforma.component.css']
})

export class ProformaComponent implements OnInit {
  proformaListenerSub: Subscription;


  displayedColumns: string[] = ['imageUrl', 'reference', 'barCode', 'presentation', 'price', 'actions'];

  fetchProforma: any = {}
  proformaTable: any = []

  category: string;
  categoryId: string;
  isLoading: boolean;

  subtotal: number
  userDiscount: number
  discount: number
  subsubtotal: number
  iva: number = IVA
  minimumPurchaseValue: number = MINIMUM_PURCHASE_VALUE;
  taxes: number
  total: number
  politicsMessage: string = POLITICS_VALUE_PURCHASE_ON_PROFORMA;

  userId: string

  constructor(private router: Router, private proformaService: ProformaService,
              private carService: ShoppingCarService, private authService: AuthClientService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0)

    const basicInfo = this.authService.getUserBasicInfo()
    this.userDiscount = basicInfo.discount
    this.userId = basicInfo.userId

    this.proformaListenerSub = this.proformaService.getProformaListener()
      .subscribe((items : {items: any}) => {
        this.fetchProforma = items.items
        this.subtotal = getSubtotal(this.fetchProforma)
        this.discount = getPercentaje(this.subtotal, this.userDiscount)
        this.subsubtotal = getSubstraction(this.subtotal, this.discount)
        this.taxes = getPercentaje(this.subsubtotal, this.iva)
        this.total = getAddition(this.subsubtotal, this.taxes)
        this.proformaTable = transformData(this.fetchProforma)
      })

    this.proformaService.getProformaData();
  }

  onInputChange(e){
    const inputValue = e.target.value
    const id = e.target.id
    let addedProduct = {}

    if(inputValue < 0){
      return
    }

    if(!inputValue || inputValue == 0){
      delete this.fetchProforma[id]
      this.proformaService.saveProformaData(this.fetchProforma)
      if(this._checkEmptyProforma(this.fetchProforma)){
        this.router.navigate([''])
      }

      return
    }

    this.fetchProforma[id].quantity = inputValue
    this.proformaService.saveProformaData(this.fetchProforma)
  }

  _checkEmptyProforma(proforma) {
    if(Object.keys(proforma).length === 0){
      return true
    }
    return false
  }

  onDeleteItem(e) {
    delete this.fetchProforma[e]
    this.proformaService.saveProformaData(this.fetchProforma)
    if(this._checkEmptyProforma(this.fetchProforma)){
      this.router.navigate([''])
    }
  }

  onSaveProforma(){
    this.isLoading = true
    let proformaItems = []
    if(Object.keys(this.fetchProforma).length > 0){
      proformaItems = getProformaItems(this.fetchProforma)
      this.carService.addItemsToShoppingCar(this.userId, proformaItems)
    }
  }
}



