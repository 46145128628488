import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-contact-confirmation.service.html',
  styleUrls: ['dialog-contact-confirmation.service.css']
})
export class DialogContactConfirmation {

}
