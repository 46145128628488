import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-expired-token.html',
  styleUrls: ['dialog-expired-token.css']
})
export class DialogExpiredToken {

}
