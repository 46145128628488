import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-video-tutorial',
  templateUrl: 'dialog-video-tutorial.html',
  styleUrls: ['dialog-video-tutorial.css'],
})
export class DialogVideoTutorial {
  videoTutorialUrl = 'https://www.youtube.com/watch?v=Zq5uftuS9y8';
}
