import { Product } from "../../../../../src/app/products/product.model";
import { Injectable } from "@angular/core";
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ProformaService {
  private proforma = new Subject<{items: any}>();
  private isEmpty = new Subject<{exists: boolean}>();
  constructor() {}

  getProformaListener(){
    return this.proforma.asObservable();
  }

  getProformaExistsListener(){
    return this.isEmpty.asObservable();
  }

  public saveProformaData(proforma: any){
    localStorage.setItem('proforma', JSON.stringify(proforma))
    this.isEmpty.next({
      exists: Object.keys(proforma)?.length > 0
    })
    this.proforma.next({items: proforma})
  }
  public clearProformaData(){
    localStorage.removeItem('proforma')
    this.isEmpty.next({
      exists: false
    })
  }
  public getProformaData(){
    const fetchedProforma = localStorage.getItem('proforma')
    if (!fetchedProforma){
      return {}
    }
    this.isEmpty.next({
      exists: Object.keys(fetchedProforma)?.length > 0
    })

    this.proforma.next({items: JSON.parse(fetchedProforma)})
  }
}





