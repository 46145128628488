import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../../../../src/app/users/clients/user.model';
import { UserService } from '../../../../../../src/app/users/clients/user.service';
import { AuthClientService } from '../auth.service';
import { CustomValidationService } from '../custom-validation.service';
import { DialogCheckActivationEmail } from './dialog/CheckEmail/dialog-check-activation-email.service';
import { DialogExistingEmail } from './dialog/EmailExisting/dialog-existing-email';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
@Component({
  selector: 'sign-up-component',
  templateUrl: './sign-up-client.component.html',
  styleUrls: ['./sign-up-client.component.css'],
})
export class SignUpClientComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isUserAuthenticated = true;
  userFormGroup: FormGroup;
  locations;
  private userId: string;
  currentProvince: string;
  currentCity: string;
  sex: string;

  provinceList: string[] = [];
  citiesList: string[] = [];

  userType: string[] = ['Cliente', 'Administrador'];
  statusCodeSub: Subscription;
  statusCode: number;

  constructor(
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private authClientService: AuthClientService,
    private validator: CustomValidationService,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.userId = null;
    this.userFormGroup = this._formBuilder.group(
      {
        names: ['', [Validators.required], []],
        ruc: [null, [], []],
        sex: ['', [Validators.required], []],
        birthday: ['', [Validators.required], []],
        lastNames: ['', [Validators.required], []],
        province: ['', [Validators.required], []],
        city: ['', [Validators.required], []],
        address: ['', [Validators.required], []],

        phone1: [null, [Validators.required], []],
        phone2: ['', [], []],
        email: ['', [Validators.required], []], //PK
        // category: ['',[Validators.required], []],
        // discount: ['',[], []],
        password: ['', [Validators.required], []],
        passwordValidate: ['', [Validators.required], []],
      },
      {
        validator: [
          this.validator.passwordValidator('password'),
          this.validator.passwordMatchValidator('password', 'passwordValidate'),
          this.validator.rucValidator('ruc'),
          this.validator.phoneValidator('phone1'),
          this.validator.birthdayValidaton('birthday'),
        ],
      }
    );

    this.userService.getLocations().subscribe((locData) => {
      this.locations = locData;
      for (let i = 0; i < this.locations.length; i++) {
        this.provinceList.push(this.locations[i]['provincia']);
      }

      this.isLoading = false;
    });

    this.sex = 'mujer';
    this.userFormGroup['controls']['sex'].setValue('mujer');

    this.statusCodeSub = this.authClientService
      .getCodeStatusListener()
      .subscribe((status) => {
        this.statusCode = status;

        if (this.statusCode === 200) {
          this.openDialogVeify();
        } else if (this.statusCode === 500) {
          this.openDialogExisting();
        }
      });
  }

  ngOnDestroy() {
    // this.statusCodeSub.unsubscribe();
  }

  getCities(index) {
    this.citiesList = this.locations[index].cantones;
  }

  onSaveUser() {
    if (this.userFormGroup.invalid) {
      return;
    }
    // console.log("SAVE A NEW USER")

    const user: User = {
      _id: null,
      ruc: this.userFormGroup.value.ruc,
      sex: this.userFormGroup.value.sex,
      birthday: this.userFormGroup.value.birthday,
      password: this.userFormGroup.value.password,
      names: this.userFormGroup.value.names,
      lastNames: this.userFormGroup.value.lastNames,
      province: this.userFormGroup.value.province,
      city: this.userFormGroup.value.city,
      address: this.userFormGroup.value.address,
      phone1: this.userFormGroup.value.phone1,
      phone2: this.userFormGroup.value.phone2,
      email: this.userFormGroup.value.email,
      category: null,
      discount: null,
    };
    this.authClientService.signUpUser(user);
  }

  openDialogVeify() {
    // console.log("11111")
    const dialogRef = this.dialog.open(DialogCheckActivationEmail);
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  openDialogExisting() {
    const dialogRef2 = this.dialog.open(DialogExistingEmail);
    dialogRef2.afterClosed().subscribe(() => {
      // this.router.navigate(['/login']);
    });
  }
}
