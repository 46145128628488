import {Component, OnDestroy, OnInit} from "@angular/core";
import {CarouselComponent} from "./carousel/carousel.component";
import {AuthClientService} from "../auth/auth.service";
import {Subscription} from "rxjs";
import {CategoryService} from "../../../../../src/app/category/category.service";
import {Category} from "../../../../../src/app/category/category.model";


@Component({
  selector: 'home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy{
  authListenerSub: Subscription;
  isLoading: boolean;
  isLogged: boolean;
  username: string;


  constructor( private authClientService: AuthClientService,
               private categoryService: CategoryService) {}

  ngOnInit() {
    window.scroll(0, 0)
    this.isLoading = false;
    this.authListenerSub = this.authClientService.getAuthStatusListener().subscribe((isAuthenticated) => {
      this.isLogged = isAuthenticated;
      this.username = this.authClientService.getUsername();
      this.isLoading = true
    })
  }

  ngOnDestroy() {
    this.authListenerSub.unsubscribe();
  }
  onLogout(){
    this.authClientService.logOutUser();
  }

}


