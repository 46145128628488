<h2 mat-dialog-title>Activar cuenta</h2>
<div class="mat-divider-container">
  <mat-divider></mat-divider>
</div>
<mat-dialog-content class="mat-typography">
  <h5>Se ha enviado un link de activación al correo proporcionado.</h5>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-raised-button color="accent" mat-dialog-close>Entendido</a>
</mat-dialog-actions>
