<h2 mat-dialog-title>Tutorial</h2>
<div class="mat-divider-container">
  <mat-divider></mat-divider>
</div>

<iframe
  width="960"
  height="515"
  src="https://www.youtube.com/embed/Zq5uftuS9y8"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowfullscreen
></iframe>

<mat-dialog-actions align="end">
  <a mat-raised-button color="accent" mat-dialog-close>Cerrar</a>
</mat-dialog-actions>
