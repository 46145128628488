import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AuthClientService} from "./auth.service";
import {Router} from "@angular/router";

@Injectable()
export class AuthClientGuard implements CanActivate{
  constructor(private authClientService: AuthClientService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLogged = this.authClientService.getIsLogged()
    if(!isLogged){
      this.router.navigate(['/login']);
    }
    return isLogged;
  }
}
