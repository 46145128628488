import {Component, OnDestroy, OnInit} from "@angular/core";
import {Product} from "../../../../../../src/app/products/product.model";
import {ProductsClientsService} from "../../products-clients.service";
import {Subscription} from "rxjs";
import {Category} from "../../../../../../src/app/category/category.model";
import {CategoryService} from "../../../../../../src/app/category/category.service";

const dataTransform = (data) => {
  let newData = {};
  for (let i = 0; i < data.length; i++){
    newData[data[i]._id] = data[i]
  }
  return newData
}


@Component({
  selector: 'carousel-component',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit, OnDestroy{
  products: Product[];
  categoriesNames: string[] = [];
  newCategoriesFormat: {}
  productSub: Subscription;
  isLoading: boolean;


  private categoriesSub: Subscription;
  productsCategories: Category[];
  constructor(private _productClientService: ProductsClientsService,
              public categoriesService: CategoryService,) {}

  ngOnInit() {
    this.isLoading = true;
    this._productClientService.getMainProducts();
    this.productSub = this._productClientService.getMainProductUpadateListener()
      .subscribe((mainProductsData) => {
        this.products = mainProductsData.products;
        this.isLoading = false;
    })

    this.categoriesService.getCategories()
    this.categoriesSub = this.categoriesService.getCategoryUpdateListener()
      .subscribe((categoriesData: {categories: Category[]}) => {
        this.isLoading = false;
        this.productsCategories = categoriesData.categories;
        this.newCategoriesFormat = dataTransform(this.productsCategories)
        for (let i = 0; i < this.products?.length; i++){
          const newName = this.newCategoriesFormat[this.products[i]?.category]?.name
          if (newName)
            this.categoriesNames.push(newName)
        }
      });

  }

  ngOnDestroy() {
    this.productSub.unsubscribe();
  }
}
