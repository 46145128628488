<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Políticas de Privacidad de Datos</title>
</head>
<body>
  <header>
    <h1>Políticas de Privacidad de Datos</h1>
  </header>
  <main>
    <section>
      <h2>Introducción</h2>
      <p>
        En Balloon, respetamos su privacidad y estamos comprometidos con
        proteger los datos personales de nuestros usuarios. Esta política de
        privacidad explica cómo recopilamos, usamos, compartimos y protegemos la
        información en relación con nuestra aplicación móvil y su uso.
      </p>
    </section>
    <section>
      <h2>Datos que Recopilamos</h2>
      <p>
        Nuestra aplicación requiere ciertos datos personales para habilitar el
        acceso y garantizar una experiencia segura y personalizada. Recabamos
        información como su nombre de usuario, dirección de correo electrónico,
        número de identificación personal y contraseñas cifradas. Esta
        información es fundamental para proporcionar un acceso seguro y
        personalizado a nuestras funcionalidades.Estos datos son:
      </p>
      <ul>
        <li>
          Información Personal: Nombres, Apellidos, Registro Único de
          Contribuyentes (RUC), Sexo, Fecha de Nacimiento, Teléfono Principal,
          Teléfono Secundario (opcional).
        </li>
        <li>Ubicación: Provincia, Ciudad, Dirección.</li>
        <li>Datos de la Cuenta: Email, Contraseña.</li>
      </ul>
    </section>
    <section>
      <h2>Uso de tus Datos</h2>
      <p>
        Nos comprometemos a utilizar tus datos personales exclusivamente para
        fines de logística y comunicación relacionada directamente con el uso de
        nuestra aplicación. Esto incluye:
      </p>
      <ul>
        <li>Creación y gestión de tu cuenta.</li>
        <li>
          Comunicación para soporte al cliente, actualizaciones importantes
          sobre la aplicación o tu cuenta.
        </li>
        <li>
          Mejora de nuestros servicios, incluyendo la personalización de tu
          experiencia en la aplicación.
        </li>
      </ul>
      <p>
        Utilizamos la información recopilada exclusivamente para validar la
        identidad del usuario, permitir acceso a diversas secciones de nuestra
        aplicación, y proteger contra actividades fraudulentas. Nuestro
        compromiso es asegurar que cada dato sirva para fortalecer la seguridad
        y mejorar la experiencia dentro de nuestra plataforma.
      </p>
    </section>
    <section>
      <h2>Compartir Datos con Terceros</h2>
      <p>
        Balloon no comparte tus datos personales con terceros, excepto como se
        requiere por ley.
      </p>
    </section>
    <section>
      <h2>Seguridad de los Datos</h2>
      <p>
        Tomamos la seguridad de tus datos personales muy seriamente. Hemos
        implementado medidas técnicas y organizativas adecuadas para proteger
        tus datos contra el acceso no autorizado, la alteración, divulgación o
        destrucción. Mantenemos tus datos personales solo durante el tiempo que
        sea necesario para los fines para los cuales se recopilaron, de acuerdo
        con nuestra política de retención de datos.
      </p>
    </section>
    <section>
      <h2>Contacto</h2>
      <p>
        Si tienes preguntas, inquietudes sobre nuestra política de privacidad, o
        si deseas ejercer alguno de tus derechos, incluyendo la solicitud de
        eliminación de tu cuenta, te invitamos a contactarnos. Puedes hacerlo de
        dos maneras:
      </p>
      <ul>
        <li>
          Directamente a través de nuestro formulario de contacto footer de
          nuestra página web.
        </li>
        <li>
          Enviándonos un correo electrónico a
          <a href="mailto:info@balloon.com.ec">info@balloon.com.ec</a>.
        </li>
      </ul>
      <p>
        Estamos comprometidos a responder todas tus consultas y solicitudes con
        la mayor brevedad posible.
      </p>
    </section>
  </main>
</body>
