import {User} from "../../../../../src/app/users/clients/user.model";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthClientService} from "../auth/auth.service";
import { environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class EditUserService {
  statusCode: number;
  statusCodeListener = new Subject<number>()
  USUARIOS_URL = environment.apiUrl + '/usuarios/';
  ACTUALIZAR_USUARIO = environment.apiUrl + '/usuarios/actualizar-usuario/';

  constructor(private httpClient: HttpClient, private authService: AuthClientService) { }

  getStatusCodeListener(){
    return this.statusCodeListener.asObservable();
  }

  getUser(id: string){
    const userId = this.authService.getUserId()
    const queryParams = `?userId=${userId}`;
    return this.httpClient.get<{_id: null, sex: null, password: null, names: null, lastNames: null,
      province: null, city: null, address: null, phone1: null, phone2: null, email: null, category: null,
      discount: null, birthday: null, ruc: null}>(this.USUARIOS_URL +id+queryParams)
  }

  updateUser(id: string, user: User){
    const userId = this.authService.getUserId()
    const queryParams = `?userId=${userId}`;
    this.httpClient.put<{message: string}>(this.ACTUALIZAR_USUARIO + id + queryParams, user)
      .subscribe((response) => {
      // console.log(response.message)
        this.statusCode = 200;
        this.statusCodeListener.next(this.statusCode)
    }, error => {
        this.statusCode = error.status;
        this.statusCodeListener.next(this.statusCode)
      })
  }



}
