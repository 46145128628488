import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsClientsService } from '../products-clients.service';
import { Product } from '../../../../../src/app/products/product.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthClientService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShoppingCarService } from '../shopping-car/shopping-car.service';
import { CarItem } from '../shopping-car/car-item.model';
import { CustomValidationService } from '../auth/custom-validation.service';
import { HostListener } from '@angular/core';

const presentationsTransformer = (presentations) => {
  let new_presentations = {};
  let weight = [];
  let units = [];
  let set = [];
  for (let i = 0; i < presentations.length; i++) {
    if (presentations[i].unitsType === 'peso') {
      weight.push(presentations[i]);
    }
    if (presentations[i].unitsType === 'unidades') {
      units.push(presentations[i]);
    }
    if (presentations[i].unitsType === 'juego') {
      set.push(presentations[i]);
    }
  }
  new_presentations['peso'] = weight;
  new_presentations['unidades'] = units;
  new_presentations['juego'] = set;

  return new_presentations;
};

@Component({
  selector: 'product-selected',
  templateUrl: './product-selected.component.html',
  styleUrls: ['./product-selected.component.css'],
})
export class ProductSelectedComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private productsClientsService: ProductsClientsService,
    private _sanitizer: DomSanitizer,
    private authClientService: AuthClientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private shoppingCarService: ShoppingCarService,
    private validator: CustomValidationService,
    private route: ActivatedRoute
  ) {}

  isClientAuthenticated = false;

  iframeTemplate: string;

  barCode: string;
  ref: string;
  category: string;
  categoryId: string;
  productId: string;
  product: Product;
  unitsList: [{}] = [{}];
  weightList: [{}] = [{}];
  amount: string[];
  presentationTypeSelected: string = null;
  price: number;

  productPresentations = {};
  selectedPresentation;

  userId: string;

  orderForm: FormGroup;

  selectedIndexPresentationType: number;

  isAvailable: boolean;

  units = 'unidades';
  presentations = [];

  quantitiesForAcquire: number[] = [];

  mainImagePath: string;
  videoUrl: string;
  hasVideo: boolean;

  isLoading: boolean;
  currentPage: string;

  safeContent: unknown;

  ngOnInit() {
    this.isLoading = true;

    window.scroll(0, 0);

    this.isClientAuthenticated = this.authClientService.getIsLogged();
    this.userId = this.authClientService.userId;

    for (let i = 1; i <= 30; i++) {
      this.quantitiesForAcquire.push(i);
    }

    this.orderForm = this.formBuilder.group(
      {
        isAvailable: [null, [Validators.required], []],
        productId: [null, [Validators.required], []],
        unitsType: [null, [], []],
        presentation: [null, [Validators.required], []],
        quantity: [null, [Validators.required], []],
      },
      {
        validator: [this.validator.integerPositiveValidator('quantity')],
      }
    );

    this._route.paramMap.subscribe((paramMap) => {
      if (paramMap.has('categoria') && paramMap.has('productId')) {
        this.category = paramMap.get('categoria');
        this.categoryId = paramMap.get('categoryId');

        this.currentPage = this.route.snapshot.queryParamMap.get('currentPage');

        this.productId = paramMap.get('productId');
        this.orderForm.get('productId').setValue(this.productId);
        // this.orderForm.get('unitsType').setValue(this.units);

        this.productsClientsService
          .getProduct(this.productId)
          .subscribe((productData) => {
            this.product = {
              _id: productData._id,
              // reference: productData.reference,
              name: productData.name,
              available: productData.available,
              mainProduct: productData.mainProduct,
              category: productData.category,
              // masterBox: productData.masterBox,
              description: productData.description,
              presentations: productData.presentations,
              image1Path: productData.image1Path,
              image2Path: productData.image2Path,
              image3Path: productData.image3Path,
              image4Path: productData.image4Path,
              image5Path: productData.image5Path,
              image6Path: productData.image6Path,

              videoUrl: productData.videoUrl,
            };

            this.videoUrl = productData.videoUrl;

            if (
              productData.videoUrl !== null &&
              productData.videoUrl !== 'NaN' &&
              productData.videoUrl !== 'null'
            ) {
              this.hasVideo = true;
              const newUrl = this.videoUrl.replace('watch?v=', 'embed/');
              this.iframeTemplate = `<iframe width="650" height="500" src="${newUrl}?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
            }

            this.isAvailable = productData.available;
            this.orderForm.get('isAvailable').setValue(this.isAvailable);
            this.mainImagePath = productData.image1Path;

            /*
                THIS BLOCK IS FOR RETRIVING JUST PRESENTATIONS AND STORE THEM EACH IN A SEPATE VARIABLE
          THIS WAY I CAN REFERENCE FROM THE VIEW AND THIS VARIABLES ARE GOING TO BE IN ORDER AND WITHOUT
          MIXING UNITS TYPES.
          */
            // I remove the first empty element filled in the declaration (I was a workaround)
            this.weightList.pop();
            this.unitsList.pop();

            for (let i = 0; i < productData.presentations.length; i++) {
              let units = this.product.presentations[i]['unitsQuantity'];
              let x = this.product.presentations[i]['large'];
              let y = this.product.presentations[i]['width'];
              let z = this.product.presentations[i]['depth'];
              let w = this.product.presentations[i]['weight'];
              let p = this.product.presentations[i]['price'];
              let pvp = this.product.presentations[i]['pvpPrice'];

              let reference = this.product.presentations[i]['reference'];
              let barCode = this.product.presentations[i]['barCode'];
              let masterBox = this.product.presentations[i]['masterBox'];

              if (productData.presentations[i]['unitsType'] === 'unidades') {
                this.unitsList.push({
                  unitsQuantity: units,
                  large: x,
                  width: y,
                  depth: z,
                  weight: w,
                  price: p,
                  pvpPrice: pvp,
                  reference: reference,
                  barCode: barCode,
                  masterBox: masterBox,
                });
              } else {
                this.weightList.push({
                  unitsQuantity: units,
                  large: x,
                  width: y,
                  depth: z,
                  weight: w,
                  price: p,
                  pvpPrice: pvp,
                  reference: reference,
                  barCode: barCode,
                  masterBox: masterBox,
                });
              }
            }

            this.productPresentations = presentationsTransformer(
              this.product.presentations
            );

            this.isLoading = false;
          });
      }
    });
  }

  onButtonPresentation(type) {
    this.selectedIndexPresentationType = 0;
    this.presentationTypeSelected = null;
    this.units = type;
  }
  getIndex(i) {
    this.selectedIndexPresentationType = i;
  }

  onChangeMainImage(img) {
    if (img === 'video') {
      this.mainImagePath = null;
      return;
    }
    this.mainImagePath = img;
  }

  getSafeUrl() {
    const newUrl = this.videoUrl.replace('watch?v=', 'embed/');
    return this._sanitizer.bypassSecurityTrustResourceUrl(newUrl);
  }

  onAddItem() {
    if (this.orderForm.invalid) {
      return;
    }

    if (this.isClientAuthenticated) {
      if (
        this.userId !== this.authClientService.checkUserIdFromLocalStorage()
      ) {
        alert(
          'Varias cuentas han iniciado sesión en el mismo navegador. Cierre las sesiones.'
        );
        this.authClientService.logOutUser();
        this.router.navigate(['login']);
        return;
      }
    }

    // if(this.units === 'unidades'){
    //   this.barCode = this.unitsList[this.selectedIndexPresentationType]['barCode'];
    //   this.ref = this.unitsList[this.selectedIndexPresentationType]['reference'];
    // } else{
    //   this.barCode = this.weightList[this.selectedIndexPresentationType]['barCode'];
    //   this.ref = this.weightList[this.selectedIndexPresentationType]['reference'];
    // }

    const item: CarItem = {
      _id: null,
      productId: this.orderForm.value.productId.split('?')[0],
      unitsType: this.orderForm.value.presentation.unitsType,
      presentation: this.orderForm.value.presentation.unitsQuantity,
      quantity: this.orderForm.value.quantity,
      barCode: this.barCode,
      reference: this.ref,
    };

    if (this.userId) {
      this.shoppingCarService.addItemToShoppingCar(this.userId, item);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
