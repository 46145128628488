<div class="outter-center">
  <div class="inner-center">
    <mat-spinner
      color="accent"
      style="margin: auto"
      *ngIf="isLoading"
    ></mat-spinner>

    <mat-card class="main-card" *ngIf="!isLoading">
      <form
        [formGroup]="userFormGroup"
        (ngSubmit)="onSaveUser()"
        *ngIf="!isLoading"
      >
        <!--                {{userFormGroup.value | json}}-->

        <div class="container main-title">
          <h4><strong>REGISTRO</strong></h4>
          <div class="mat-divider-container">
            <mat-divider></mat-divider>
          </div>
        </div>

        <div class="container">
          <mat-card class="sub-field">
            <div class="container">
              <h5>Información personal</h5>
              <div class="mat-divider-container">
                <mat-divider></mat-divider>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6 grid-container">
                <div class="center-icon">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Nombres</mat-label>
                    <input
                      matInput
                      required
                      type="text"
                      formControlName="names"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Apellidos</mat-label>
                  <input
                    matInput
                    required
                    type="text"
                    formControlName="lastNames"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa fa-id-card-o" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>RUC</mat-label>
                    <input
                      matInput
                      required
                      type="text"
                      [ngClass]="{
                        'is-invalid':
                          userFormGroup.get('ruc').errors &&
                          userFormGroup.get('ruc').touched
                      }"
                      formControlName="ruc"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="userFormGroup.get('ruc').errors"
                    >
                      Identificación inválida.
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-5">
                <div>
                  <label id="example-radio-group-label">Sexo</label>
                </div>
                <mat-radio-group aria-label="Sexo" formControlName="sex">
                  <mat-radio-button value="mujer">Mujer</mat-radio-button>
                  <mat-radio-button value="hombre">Hombre</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="col-md-7 grid-container">
                <div class="center-icon">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Fecha de nacimiento (mm/dd/aaaa)</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      [ngClass]="{
                        'is-invalid':
                          userFormGroup.get('birthday').errors &&
                          userFormGroup.get('birthday').touched
                      }"
                      formControlName="birthday"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="userFormGroup.get('birthday').errors"
                    >
                      Fecha de nacimiento incorrecta
                    </div>

                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon
                        >keyboard_arrow_down</mat-icon
                      >
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6 grid-container">
                <div class="center-icon">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Teléfono principal</mat-label>

                    <!--                    <input matInput-->
                    <!--                           type="text"-->
                    <!--                           [ngClass]="{'is-invalid': userFormGroup.get('ruc').errors && userFormGroup.get('ruc').touched}"-->
                    <!--                           formControlName="ruc">-->

                    <input
                      matInput
                      required
                      type="text"
                      formControlName="phone1"
                      [ngClass]="{
                        'is-invalid':
                          userFormGroup.get('phone1').errors &&
                          userFormGroup.get('phone1').touched
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="userFormGroup.get('phone1').errors"
                    >
                      Teléfono solo puede ser numérico
                    </div>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-6">
                <div>
                  <mat-form-field>
                    <mat-label>Teléfono secundario (Opcional)</mat-label>
                    <input matInput type="text" formControlName="phone2" />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Provincia</mat-label>
                  <mat-select required formControlName="province">
                    <mat-option
                      *ngFor="let p of provinceList; let i = index"
                      [value]="p"
                      (click)="getCities(i)"
                    >
                      {{ p }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Ciudad</mat-label>
                  <mat-select required formControlName="city">
                    <mat-option
                      *ngFor="let c of citiesList; let i = index"
                      [value]="c"
                    >
                      {{ c }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Dirección</mat-label>
                    <input matInput type="text" formControlName="address" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="container">
          <mat-card class="sub-field">
            <div class="container">
              <h5>Datos de la cuenta</h5>
              <div class="mat-divider-container">
                <mat-divider></mat-divider>
              </div>
            </div>

            <div class="container row">
              <div class="col-md-12 grid-container">
                <div class="center-icon">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      required
                      type="email"
                      formControlName="email"
                      email
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row container">
              <div class="col-md-6 grid-container">
                <div class="center-icon">
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </div>
                <div>
                  <mat-form-field class="passw-field">
                    <mat-label>Contraseña</mat-label>
                    <input
                      required
                      matInput
                      [ngClass]="{
                        'is-invalid':
                          userFormGroup.get('password').errors &&
                          userFormGroup.get('password').touched
                      }"
                      formControlName="password"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        userFormGroup.get('password').hasError('invalidPass')
                      "
                    >
                      No cumple requisitos mínimos.
                    </div>
                  </mat-form-field>
                  <div
                    class="min-req"
                    [ngStyle]="{
                      color: userFormGroup.get('password').errors
                        ? '#E91E63'
                        : '#2d930d'
                    }"
                  >
                    <div>Mínimo 5 caracteres de longitud.</div>
                    <div>Mínimo 1 letra en mayúscula.</div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Confirmar contraseña</mat-label>
                  <input
                    class=""
                    [ngClass]="{
                      'is-invalid':
                        userFormGroup.get('passwordValidate').errors &&
                        userFormGroup.get('passwordValidate').touched
                    }"
                    matInput
                    type="password"
                    formControlName="passwordValidate"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      userFormGroup
                        .get('passwordValidate')
                        .hasError('passwordMismatch')
                    "
                  >
                    Contraseñas diferentes.
                  </div>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="flex-box container">
          <button mat-raised-button color="accent" type="submit">
            <strong>Crear usuario</strong>
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
