import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CarouselComponent } from './home/carousel/carousel.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';

import { NgForm, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { CategoriesMenuComponent } from './home/categories-menu/categories-menu.component';

import { FooterComponent } from './footer/footer.component';
import { ProductsClientsListComponent } from './products-clients-list/products-clients-list.component';

import { ProductSelectedComponent } from './product-selected/product-selected.component';
import { ProductsClientsService } from './products-clients.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginClientComponent } from './auth/login/login-client.component';
import { SignUpClientComponent } from './auth/sign-up/sign-up-client.component';

import { AuthClientService } from './auth/auth.service';
import { ShoppingCarComponent } from './shopping-car/shopping-car.component';
import { ShoppingCarService } from './shopping-car/shopping-car.service';
import { AuthClientInterceptor } from './auth/auth-client-interceptor';
import { AuthClientGuard } from './auth/auth-client.guard';
import { ContactFormService } from './footer/contact-form.service';
import { HeaderServices } from './header/header.services';
import { getDutchPaginatorIntl } from './products-clients-list/custom-paginator.component';
import { CustomValidationService } from './auth/custom-validation.service';
import { EditUserService } from './edit-user/edit-user.service';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PasswordRecoverComponent } from './auth/password-recover/request-recover/password-recover.component';
import { PasswordRecoverService } from './auth/password-recover/password-recover.service';
import { ChangePasswordComponent } from './auth/password-recover/change-password/change-password.component';
import { DialogCheckEmail } from './auth/password-recover/dialogs/check-email/dialog-check-email.service';
import { DialogPasswordUpdated } from './auth/password-recover/dialogs/password-updated/dialog-password-updated.service';
import { DialogEditUserConfirmation } from './edit-user/dialog/dialog-edit-user-confirmation.service';
import { DialogContactConfirmation } from './footer/dialog/dialog-contact-confirmation.service';
import { DialogAskOrderConfirmation } from './shopping-car/dialogs/ask-confirmation/dialog-ask-confirmation.service';
import { DialogShowOrderConfirmation } from './shopping-car/dialogs/show-confirmation/dialog-show-confirmation.service';
import { ActivationAccountComponent } from './auth/activation-account/activation-account.component';
import { DialogCheckActivationEmail } from './auth/sign-up/dialog/CheckEmail/dialog-check-activation-email.service';
import { DialogExistingEmail } from './auth/sign-up/dialog/EmailExisting/dialog-existing-email';
import { AngularMaterialModule } from './angular-material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DialogCheckActivationEmailSent } from './auth/login/CheckEmail/dialog-check-activation-email-sent';
import { DialogExpiredToken } from './auth/login/ExpiredToken/dialog-expired-token';
import { SafePipe } from './pipes/safe.pipe';
import { MatTableModule } from '@angular/material/table';
import { ProformaComponent } from './proforma/proforma.component';
import { CurrentPageService } from './products-clients-list/current-page.service';
import { DialogVideoTutorial } from './header/video-dialog/dialog-video-tutorial';
import { PrivacyComponent } from './privacy/privacy.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CarouselComponent,
    HomeComponent,
    CategoriesMenuComponent,
    FooterComponent,
    ProductsClientsListComponent,
    ProductSelectedComponent,
    LoginClientComponent,
    SignUpClientComponent,
    ShoppingCarComponent,
    EditUserComponent,
    PasswordRecoverComponent,
    ChangePasswordComponent,
    DialogPasswordUpdated,
    DialogCheckEmail,
    DialogEditUserConfirmation,
    DialogContactConfirmation,
    DialogCheckActivationEmail,
    DialogVideoTutorial,
    DialogExistingEmail,
    DialogShowOrderConfirmation,
    DialogAskOrderConfirmation,
    DialogCheckActivationEmailSent,
    DialogExpiredToken,
    ActivationAccountComponent,
    SafePipe,
    ProformaComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularMaterialModule,
    MatTableModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthClientInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
