import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgForm} from "@angular/forms";
import {PasswordRecoverService} from "../password-recover.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthClientService} from "../../auth.service";
import {Subscription} from "rxjs";
import {DialogCheckEmail} from "../dialogs/check-email/dialog-check-email.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'password-recover-component',
  templateUrl: 'password-recover.component.html',
  styleUrls: ['./password-recover.component.css']
})
export class PasswordRecoverComponent implements OnInit, OnDestroy{
  constructor(private recoverPasswordService: PasswordRecoverService, private router: Router, public dialog: MatDialog,
              private authService: AuthClientService, private activeRoute: ActivatedRoute) { }

  isLoading: boolean;
  isLogged: boolean;
  userEmail:string = '';
  statusCode: number;
  statusCodeSub: Subscription;

  ngOnDestroy() {
    this.statusCodeSub.unsubscribe()
  }

  ngOnInit() {
    this.isLoading = true;

    window.scroll(0, 0)

    this.isLogged = this.authService.getIsLogged()


    this.activeRoute.paramMap.subscribe((paramMap) => {
      if(paramMap.has('userEmail')){
        this.userEmail = paramMap.get('userEmail')
      }
    })

    this.statusCodeSub = this.recoverPasswordService.getStatusCodeListener()
      .subscribe((status) => {
         this.statusCode = status;

         if(this.statusCode === 200){
           this.openDialog();
         }
      })

    this.isLoading = false;

  }


  onRecoverPassword(emailForm: NgForm){
    if(emailForm.invalid){
      return
    }
    this.recoverPasswordService.recoverPassword(emailForm.value.email);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogCheckEmail);

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }


}
