import { Product } from "../../../../src/app/products/product.model";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {AuthClientService} from "./auth/auth.service";
import {environment} from "../environments/environment";

@Injectable({providedIn: 'root'})
export class ProductsClientsService {
  private products: Product[] = [];
  private mainProducts: Product[] = [];
  private productsUpdated = new Subject<{products: Product[], productCount: number}>();
  private mainProductsUpdated = new Subject<{products: Product[]}>();

  GET_PRODUCTS_URL = environment.apiUrl + '/productos/';
  GET_POPULAR_PRODUCTS_URL = environment.apiUrl + '/productos/populares';

  constructor(private http: HttpClient, private router: Router,
              private authService: AuthClientService) {}

  getProductUpdateListener(){
    return this.productsUpdated.asObservable();
  }

  getMainProductUpadateListener(){
    return this.mainProductsUpdated.asObservable();
  }

  getProducts(category:string, productsPerPage: number, currentPage: number) {
    const id = this.authService.getUserId()
    const queryParams = `?category=${category}&pageSize=${productsPerPage}&page=${currentPage}&userId=${id}`;
    this.http.get<{message: string, products: Product[], maxProducts: number}>(this.GET_PRODUCTS_URL + queryParams)
      .subscribe((productsData) => {
        // console.log(productsData.message)
        this.products = productsData.products;
        //getting stored products in proforma
        this.productsUpdated.next({
          products: [...this.products],
          productCount: productsData.maxProducts,
        });
      });
  }

  getProduct(id: string){
    const productId = id.split('?')[0]
    const cameFrom = id.split('?')[1]
    const userId = this.authService.getUserId()
    let queryParams: string
    if(cameFrom){
      queryParams = `?userId=${userId}&cameFrom=${cameFrom}`;
    } else {
      queryParams = `?userId=${userId}`;
    }
    return this.http.get<{_id: null, name: null,
      available: null, mainProduct: null, category: null,
      description: null, presentations:[], image1Path: string,
      image2Path: string, image3Path: string, image4Path: string,
      image5Path: string, image6Path: string, videoUrl: string}>(this.GET_PRODUCTS_URL+ productId + queryParams)
  }

  getMainProducts(){
    const userId = this.authService.getUserId()
    const queryParams = `?userId=${userId}`;
    return this.http.get<{message:string, products: Product[]}>(this.GET_POPULAR_PRODUCTS_URL +queryParams)
      .subscribe((productsData) => {
        // console.log(productsData.message);
        this.mainProducts = productsData.products;
        this.mainProductsUpdated.next({
          products: [...this.mainProducts]
        })
      });
  }
}





