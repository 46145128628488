import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {Product} from "../../../../../src/app/products/product.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsClientsService} from "../products-clients.service";
import {PageEvent} from "@angular/material/paginator";
import {ProformaService} from "../proforma/proforma.service";
import {CurrentPageService} from "./current-page.service";


@Component({
  selector: 'products-clients-list',
  templateUrl: './products-clients-list.component.html',
  styleUrls: ['./products-clients-list.component.css']
})


export class ProductsClientsListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['reference', 'presentation', 'master', 'price', 'actions'];

  proforma: any = {}

  productId: string;

  category: string;
  categoryId: string;
  isLoading: boolean;

  totalProducts = 0;
  productsPerPage = 10;
  pageSizeOptions = [1]
  currentPage = 1;

  products: Product[];
  private productsSub: Subscription;
  private proformaListenerSub: Subscription;

  constructor(private _route: ActivatedRoute,
              private productsService: ProductsClientsService,
              private proformaService: ProformaService,
              private route: ActivatedRoute,
              private router: Router,
              private currentPageService: CurrentPageService) {
  }

  ngOnDestroy() {
      this.productsSub?.unsubscribe();
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.isLoading = true;

    this._route.paramMap.subscribe((params) => {

      if(this.currentPageService.getCurrentPage()){
        this.currentPage = parseInt(this.currentPageService.getCurrentPage())
        this.currentPageService.clearCurrentPage();
      }

      this.category = params.get('categoria');
      this.categoryId = params.get('categoryId')

      this.productId = this.route.snapshot.queryParamMap.get('productId');


      if(this.productId){
        this.productsService.getProduct(this.productId)
          .subscribe((productData) => {

            this.products = [productData];
            this.totalProducts = 1;
            this.isLoading = false;
          })
      } else {
        this.productsService.getProducts(this.categoryId, this.productsPerPage, this.currentPage);
        this.productsSub = this.productsService.getProductUpdateListener()
          .subscribe((productsData) => {
            this.products = productsData.products.filter(p => p.available);
            this.totalProducts = productsData.productCount;
            this.isLoading = false;
          })
      }

      this.proformaListenerSub = this.proformaService.getProformaListener()
        .subscribe((items : {items: any}) => {
          this.proforma = items.items
        })
      this.proformaService.getProformaData();

    })
  }

  onViewProductsDetails (category, categoryId, productId) {
    this.currentPageService.saveCurrentPage(this.currentPage);
    this.router.navigate(['/productos/' + category + '/' + categoryId + '/' + productId])
  }




  onChangedPage(pageData: PageEvent) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.productsPerPage = pageData.pageSize;
    this.productsService.getProducts(this.categoryId, this.productsPerPage, this.currentPage)
  }

  onDelete(e) {
    if(!e){
      return
    }
  }

  onInputChange(e){
    const inputValue = e.target.value
    const id = e.target.id
    let addedProduct = {}

    if(inputValue <= 0){
      return
    }

    if(!inputValue || inputValue === 0){
      delete this.proforma[id]
      this.proformaService.saveProformaData(this.proforma)
      return
    }

    for(let i = 0; i < this.products.length; i++){
      for(let j = 0; j < this.products[i].presentations.length; j++){
        // @ts-ignore
        if(this.products[i].presentations[j]?.reference == id){
          addedProduct = {
            _id: "",
            productId: this.products[i]?._id,
            // @ts-ignore
            unitsType: this.products[i].presentations[j]?.unitsType,
            // @ts-ignore
            presentation: this.products[i].presentations[j]?.unitsQuantity,
            quantity: inputValue,
            // @ts-ignore
            barCode: this.products[i].presentations[j]?.barCode,
            // @ts-ignore
            reference: this.products[i].presentations[j]?.reference,
            imageUrl: this.products[i].image1Path,
            // @ts-ignore
            price: this.products[i].presentations[j]?.price,
            // @ts-ignore
            pvpPrice: this.products[i].presentations[j]?.pvpPrice,
            category: this.category,
            categoryId: this.categoryId
          }
          break
        }
      }
    }

    this.proforma[`${id}`] = addedProduct
    this.proformaService.saveProformaData(this.proforma)
  }
}



