<mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

<div class="container" *ngIf="!isLoading">
  <section class="mat-typography title-group">
      <span class="title-group">
        <a mat-button routerLink="/" routerLinkActive="mat-accent"><h2>Home</h2></a></span>
    <span class="spacer "></span>
    <ul class="title-group">
      <li class="title-group">
        <a mat-button routerLink="/productos/{{category}}/{{categoryId}}" routerLinkActive="mat-accent">
          <h3>{{category}}</h3></a>
      </li>
    </ul>
    <mat-divider></mat-divider>
  </section>


  <mat-card *ngFor="let p of products; let i = index">
<!--    <a routerLink="/productos/{{category}}/{{categoryId}}/{{p._id}}">-->
      <a style="width: 100%; text-align: left" mat-button (click)="onViewProductsDetails(category, categoryId, p._id)" routerLinkActive="mat-accent">

      <div class="product-desc-container">
        <div class="img-thumbnail">
          <img [src]="p.image1Path">
        </div>
        <div class="description">
          <div class="characteristics">
            <h4>{{p.name}}</h4>
          </div>
          <div class=" mat-divider-container">
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
    </a>

    <div class="table-container">
      <table mat-table [dataSource]="p?.presentations" class="mat-elevation-z2">
        <!-- Reference Column -->
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef> Referencia</th>
          <td mat-cell *matCellDef="let element"> {{element?.reference}} </td>
        </ng-container>

        <!-- Presentation Column -->
        <ng-container matColumnDef="presentation">
          <th mat-header-cell *matHeaderCellDef> Presentación</th>
          <td mat-cell *matCellDef="let element"> {{element?.unitsQuantity}} </td>
        </ng-container>

        <!-- MasterBox Column -->
        <ng-container matColumnDef="master">
          <th mat-header-cell *matHeaderCellDef> Cantidad caja master</th>
          <td mat-cell *matCellDef="let element"> {{element?.masterBox}} </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Precio al por mayor</th>
          <td mat-cell *matCellDef="let element"> $ {{element?.price}} </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Cantidad</th>
          <td mat-cell *matCellDef="let element">

            <mat-form-field appearance="fill">
              <mat-label>Cantidad a adquirir</mat-label>
              <label>

                <input (change)="onInputChange($event)"
                       [value]="proforma[element.reference]?.quantity"
                       id="{{element.reference}}"
                       matInput
                       type="number"
                       min=0>
              </label>
            </mat-form-field>

            <div class="row-field-container">


<!--              <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">-->
<!--                <mat-icon>pageview</mat-icon>-->
<!--              </button>-->
            </div>


          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>


  </mat-card>


</div>

<div class="mat-paginator-container container" *ngIf="products">
  <mat-paginator [length]="totalProducts" [pageSize]="productsPerPage" [pageSizeOptions]="pageSizeOptions"
                 (page)="onChangedPage($event)"
                 pageIndex="{{currentPage - 1}}"
                 *ngIf="products.length > 0"></mat-paginator>
  <p *ngIf="products.length <= 0 && !isLoading" class="info-text">No hay productos añadidos!</p>
</div>

