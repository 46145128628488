<div class="container" *ngIf="isRequestValid">

  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <div class="block">
      <h3><strong>RESTABLECER CONTRASEÑA</strong></h3>
    </div>

    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div class="block">
      <form [formGroup]="passForm"  (ngSubmit)="onChangePassword()">
        <div class="grid-container">

          <div class="center-icon">
            <mat-icon>lock</mat-icon>
          </div>
          <div>
            <mat-form-field>
              <input matInput
                     formControlName="password"
                     required
                     [ngClass]="{'is-invalid': passForm.get('password').errors && passForm.get('password').touched}"
                     name="password"
                     type="text"
                     placeholder="Contraseña nueva" >
              <div class="invalid-feedback" *ngIf="passForm.get('password').hasError('invalidPass')">
                No cumple requisitos mínimos.
              </div>
            </mat-form-field>
          </div>
        </div>

        <div class="grid-container">

          <div class="center-icon">
            <mat-icon>lock</mat-icon>
          </div>
          <div>
            <mat-form-field>
              <input matInput
                     required
                     [ngClass]="{'is-invalid': passForm.get('passwordValidation').errors && passForm.get('passwordValidation').touched}"
                     formControlName="passwordValidation"
                     type="password"
                     placeholder="Confirmar constraseña" >
              <div class="invalid-feedback" *ngIf="passForm.get('passwordValidation').hasError('passwordMismatch')">
                Contraseñas diferentes.
              </div>
            </mat-form-field>
          </div>
        </div>



        <div class="min-req"
             [ngStyle]="{'color':passForm.get('password').errors  ? '#E91E63' : '#2d930d' }">
        <div>
            Mínimo 5 caracteres de longitud.
          </div>
          <div>
            Mínimo 1 letra en mayúscula.
          </div>
        </div>



        <div class="block">
          <button mat-raised-button type="submit" color="accent"><strong>Restablecer contraseña</strong></button>
        </div>

      </form>
    </div>

    <div class="block" *ngIf="!isLogged">
      ¿No eres miembro? <a routerLink="/registro">¡Regístrate!</a>
    </div>

  </mat-card>

</div>


