<!--THIS IS THE CURRENT HEADER IT WORKS, SO JUST UNCOMMENT IF YOU NEED IT-->

<div class="grid-navbar-container img-fluid">
  <!--  div for the logo in the left-->
  <div class="img-toolbar-container">
    <div class="logo">
      <!--      <a routerLink="/"><img src="./assets/LogoTextoYSimbolo.png"></a>-->
      <a routerLink="/"><img src="./assets/logoBALLOONH.png" /></a>
    </div>
  </div>

  <!--  DIV with the search bar and the HOLA user tag-->

  <div class="grid-navbar-element">
    <div class="superior-grid-container">
      <div class="seach-bar-container">
        <form class="example-form">
          <mat-form-field
            class="example-full-width"
            floatLabel="never"
            appearance="none"
          >
            <input
              type="text"
              placeholder=" Buscar producto"
              aria-label="Number"
              matInput
              class="ng-pristine ng-invalid ng-invalid-required ng-touched"
              [formControl]="myControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async; let i = index"
                [value]="option"
                (onSelectionChange)="searchProduct(option)"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      </div>

      <div class="name-container" *ngIf="isClientAuthenticated">
        <a mat-button
          ><h5>
            <strong>Hola {{ username }}</strong>
          </h5></a
        >
      </div>
    </div>

    <!--    DIV with the left options-->
    <div class="options-container">
      <span class="spacer"></span>
      <ul>
        <li>
          <div>
            <a mat-button (click)="onDisplayTutorial()">
              <mat-icon
                style="display: inline; margin: 0 0 0 1rem; font-size: 30px"
                >ondemand_video</mat-icon
              >
              <h5 style="display: inline"><strong>Tutorial</strong></h5></a
            >
          </div>
        </li>

        <li>
          <div *ngIf="isClientAuthenticated && proformaExists">
            <a mat-button routerLink="proforma">
              <mat-icon
                style="display: inline; margin: 0 0 0 1rem; font-size: 30px"
                >list</mat-icon
              >
              <h5 style="display: inline"><strong>Proforma</strong></h5></a
            >
          </div>
        </li>
        <li>
          <div *ngIf="isClientAuthenticated">
            <a mat-button routerLink="carro-compras">
              <mat-icon
                style="display: inline; margin: 0 0 0 1rem; font-size: 30px"
                >shopping_cart</mat-icon
              >
              <h5 style="display: inline"><strong>Tu carrito</strong></h5></a
            >
          </div>
        </li>
        <li>
          <div *ngIf="!isClientAuthenticated">
            <a mat-button routerLink="login">
              <mat-icon
                style="display: inline; margin: 0 0 0 0rem; font-size: 30px"
                >exit_to_app</mat-icon
              >
              <h5 style="display: inline; margin: 0 0 0 0rem">
                <strong>Ingresar</strong>
              </h5></a
            >
          </div>

          <div *ngIf="isClientAuthenticated">
            <a mat-button [matMenuTriggerFor]="beforeMenu">
              <mat-icon
                style="display: inline; margin: 0 0 0 1rem; font-size: 30px"
                >account_circle</mat-icon
              >
              <h5 style="display: inline"><strong>Tu cuenta</strong></h5></a
            >
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="onEditAccount()">
                <mat-icon>edit</mat-icon>
                <span>Editar cuenta</span>
              </button>

              <button mat-menu-item (click)="onLogout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Cerrar sesión</span>
              </button>
            </mat-menu>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
