import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductsClientsListComponent } from './products-clients-list/products-clients-list.component';
import { ProductSelectedComponent } from './product-selected/product-selected.component';
import { LoginClientComponent } from './auth/login/login-client.component';
import { SignUpClientComponent } from './auth/sign-up/sign-up-client.component';
import { ShoppingCarComponent } from './shopping-car/shopping-car.component';
import { AuthClientGuard } from './auth/auth-client.guard';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PasswordRecoverComponent } from './auth/password-recover/request-recover/password-recover.component';
import { ChangePasswordComponent } from './auth/password-recover/change-password/change-password.component';
import { ActivationAccountComponent } from './auth/activation-account/activation-account.component';
import { ProformaComponent } from './proforma/proforma.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'privacidad', component: PrivacyComponent },

  { path: 'login', component: LoginClientComponent },

  { path: 'registro', component: SignUpClientComponent },

  { path: 'restablecer-contrasena', component: PasswordRecoverComponent },
  {
    path: 'restablecer-contrasena/:userEmail',
    component: PasswordRecoverComponent,
  },

  { path: 'resetear-contrasena/:token', component: ChangePasswordComponent },

  { path: 'activar/:token', component: ActivationAccountComponent },

  {
    path: 'editar',
    component: EditUserComponent,
    canActivate: [AuthClientGuard],
  },

  {
    path: 'proforma',
    component: ProformaComponent,
    canActivate: [AuthClientGuard],
  },

  {
    path: 'carro-compras',
    component: ShoppingCarComponent,
    canActivate: [AuthClientGuard],
  },

  {
    path: 'productos/:categoria/:categoryId',
    component: ProductsClientsListComponent,
  },

  {
    path: 'productos/:categoria/:categoryId/:productId',
    component: ProductSelectedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthClientGuard],
})
export class AppRoutingModule {}
