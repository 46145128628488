import {Component, OnDestroy, OnInit} from "@angular/core";
import {NgForm} from "@angular/forms";
import {AuthClientService} from "../auth.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {DialogCheckActivationEmailSent} from "./CheckEmail/dialog-check-activation-email-sent";
import {DialogExpiredToken} from "./ExpiredToken/dialog-expired-token";

import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'login-component',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.css']
})
export class LoginClientComponent implements OnInit, OnDestroy{
  isLoggin = true;
  isLoading = true;
  statusCode: number;
  statusCodeSub: Subscription;
  status: string;

  constructor(private authClientService: AuthClientService,
              private router: Router,
              private dialog: MatDialog,
  ) { }

  ngOnInit() {
    window.scroll(0, 0)
    this.isLoading = false;
    this.isLoggin = this.authClientService.getIsLogged()

    this.statusCodeSub = this.authClientService.getCodeStatusListener().subscribe((status) => {
      this.statusCode = status;
      if(this.statusCode === 401){
        this.openDialogVeifyEmailSent();
      } else if (this.statusCode === 403){
        this.openDialogExpiredToken();
      }
    })

    if(this.isLoggin){
      this.router.navigate(['/'])
    }

  }

  openDialogVeifyEmailSent() {
    const dialogRef = this.dialog.open(DialogCheckActivationEmailSent);
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  openDialogExpiredToken() {
    const dialogRef2 = this.dialog.open(DialogExpiredToken);
    dialogRef2.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  ngOnDestroy() {
    this.statusCodeSub.unsubscribe();
  }

  onLogin(form: NgForm){
    if(form.invalid){
      return
    }
    this.authClientService.loginUser(form.value.username, form.value.password);
    form.resetForm()
  }
}
