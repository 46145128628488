<div class="container">
  <section class="mat-typography title-group">
    <span class="title-group">
      <a mat-button routerLink="/" routerLinkActive="mat-accent"
        ><h2>Home</h2></a
      ></span
    >
    <span class="spacer"></span>

    <mat-divider></mat-divider>
  </section>

  <mat-spinner
    color="accent"
    style="margin: auto"
    *ngIf="isLoading"
  ></mat-spinner>

  <mat-card class="main-card" *ngIf="!isLoading">
    <!--    {{shoppingCar | json}}-->

    <div class="main-grid" *ngIf="shoppingCar">
      <div class="grid-item-products">
        <div class="client-description-container">
          <h4><strong>Datos de contacto</strong></h4>
          <div class="mat-divider-container"><mat-divider></mat-divider></div>

          <mat-card>
            <div class="user-info-grid">
              <div class="desc-column">Nombres:</div>
              <div>
                {{ shoppingCar.userNames }} {{ shoppingCar.userLastNames }}
              </div>

              <div class="desc-column">Provincia:</div>
              <div>
                {{ shoppingCar.userProvince }}
              </div>

              <div class="desc-column">RUC:</div>
              <div>
                {{ shoppingCar.userRUC }}
              </div>

              <div class="desc-column">Ciudad:</div>
              <div>
                {{ shoppingCar.userCity }}
              </div>

              <div class="desc-column">Teléfonos:</div>
              <div>
                {{ shoppingCar.userPhone1 }} / {{ shoppingCar.userPhone2 }}
              </div>

              <div class="desc-column">Dirección:</div>
              <div>
                {{ shoppingCar.userAddress }}
              </div>

              <div class="desc-column">Email:</div>
              <div>
                {{ shoppingCar.userEmail }}
              </div>
            </div>
          </mat-card>
        </div>

        <h4>
          <strong>Productos a comprar</strong> &nbsp;&nbsp;
          <strong *ngIf="userDiscount > 0" style="color: #20b844"
            >Descuento: {{ shoppingCar.userDiscount }}%</strong
          >
        </h4>
        <div class="mat-divider-container"><mat-divider></mat-divider></div>

        <mat-card
          class="products-container"
          *ngFor="let carItem of shoppingCar.carItems; let i = index"
        >
          <div class="product-desc-container">
            <div class="img-thumbnail">
              <img [src]="carItem.imgPath" />
            </div>

            <div class="description">
              <div class="characteristics">
                <h6>{{ carItem.product }}</h6>
              </div>

              <div class="characteristics">
                <h6>Cantidad: {{ carItem.quantity }}</h6>
              </div>

              <div class="characteristics">
                <h6>
                  Presentación: {{ carItem.unitsType }} x
                  {{ carItem.presentation }}
                </h6>
              </div>

              <div class="characteristics">
                <h6>Código de barras: {{ carItem.barCode }}</h6>
              </div>

              <div class="characteristics">
                <button mat-button (click)="onDeleteItem(i)" color="accent">
                  <h6>Eliminar producto</h6>
                </button>
              </div>
            </div>

            <div
              class="prices-container"
              *ngIf="shoppingCar.userDiscount > 0; else elsePriceDiscount"
            >
              <div class="price-without-discount">
                <h5>$ {{ carItem.price }}</h5>
              </div>
              <div style="color: #20b844">
                <h5>$ {{ carItem.priceWithUserDiscount }}</h5>
              </div>
            </div>
            <ng-template #elsePriceDiscount>
              <div>
                <h5>$ {{ carItem.price }}</h5>
              </div>
            </ng-template>
          </div>
        </mat-card>

        <mat-card class="total-price-container">
          <div>
            <h5>Valor: USD$ {{ subtotal }}</h5>
          </div>
        </mat-card>

        <h4><strong>Información adicional</strong> &nbsp;</h4>
        <div class="mat-divider-container"><mat-divider></mat-divider></div>

        <mat-card>
          <form [formGroup]="orderForm">
            <mat-form-field class="extra-info-container" appearance="fill">
              <mat-label
                >¿Algún detalle adicional sobre tu pedido?
                ¡Coméntanos!</mat-label
              >
              <textarea
                matInput
                placeholder=""
                formControlName="extraInformation"
              ></textarea>
            </mat-form-field>
          </form>
        </mat-card>
      </div>

      <!--      THIS CARD IS FOR THE PRICE-->
      <div class="grid-item prices-info-container">
        <mat-card>
          <div>
            <h6>Valor: $ {{ subtotal }}</h6>
          </div>
          <div>
            <h6>{{ userDiscount }}% Desc. : $ {{ discount }}</h6>
          </div>
          <div>
            <h6>Subtotal: $ {{ subsubtotal }}</h6>
          </div>
          <div>
            <h6>{{ iva }}% I.V.A. : $ {{ taxes }}</h6>
          </div>
          <div>
            <h5>Total: $ {{ total }}</h5>
          </div>
          <div>
            <a
              mat-raised-button
              color="accent"
              (click)="openDialogAskConfirmation()"
              ><strong>Realizar pedido</strong></a
            >
          </div>
        </mat-card>
        <!-- <mat-card
          *ngIf="total < minimumPurchaseValue"
          style="color: orangered; margin-top: 1rem"
        >
          {{ politicsMessage }}
        </mat-card> -->
      </div>
    </div>

    <div *ngIf="!shoppingCar">
      <h5>Carro de compras vacío.</h5>
      <div class="mat-divider-container"><mat-divider></mat-divider></div>
    </div>
  </mat-card>
</div>
