import {Component, OnDestroy, OnInit} from "@angular/core";
import {User} from "../../../../../src/app/users/clients/user.model";
import {UserService} from "../../../../../src/app/users/clients/user.service";
import {AuthClientService} from "../auth/auth.service";
import {DialogEditUserConfirmation} from "./dialog/dialog-edit-user-confirmation.service";
import {
  AbstractControl,
  NgForm,
  FormArray,
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
  FormControl,
} from "@angular/forms";
import {ActivatedRoute, convertToParamMap, ParamMap, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {EditUserService} from "./edit-user.service";
import {PasswordRecoverService} from "../auth/password-recover/password-recover.service";
import {MatDialog} from "@angular/material/dialog";
@Component({
  selector: 'edit-user-component',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnDestroy{
  isLoading: boolean;
  user:User;
  userEmail: string
  isUserAuthenticated = false
  userFormGroup: FormGroup;
  locations;
  private userId: string;
  currentProvince: string;
  currentCity: string;
  sex:string;

  provinceList: string [] = []
  citiesList: string[] = []

  userType: string[] = ['Cliente', 'Administrador'];

  statusCode: number;
  statusCodeSub: Subscription



  constructor(private _formBuilder: FormBuilder, private userService: UserService,
              private authClientService: AuthClientService,
              public route: ActivatedRoute,
              private editUserService: EditUserService,
              private router: Router,
              private passwordRecoverService: PasswordRecoverService,
              private dialog: MatDialog) { }


   ngOnDestroy() {
    this.statusCodeSub.unsubscribe();

   }

  ngOnInit() {
    this.isUserAuthenticated = this.authClientService.getIsLogged()
    this.isLoading = true;
    this.userId = this.authClientService.getUserId()

    this.userFormGroup = this._formBuilder.group({
        names: ['',[Validators.required], []],
        ruc: [null,[], []],
        sex: ['',[Validators.required], []],
        birthday: ['',[Validators.required], []],
        lastNames: ['',[Validators.required], []],
        province: ['',[Validators.required], []],
        city: ['',[Validators.required], []],
        address: ['',[Validators.required], []],
        phone1: ['',[Validators.required], []],
        phone2: ['',[], []],
      });


    this.userService.getLocations().subscribe(locData => {
      this.locations = locData;
      for(let i = 0; i < this.locations.length; i++){
        this.provinceList.push(this.locations[i]['provincia']);
      }
      this.isLoading = false
    })

    this.editUserService.getUser(this.userId).subscribe((userData) => {

      this.user = {
        _id: userData._id,
        ruc: userData.ruc,
        sex: userData.sex,
        birthday: userData.birthday,
        password: userData.password,
        names: userData.names,
        lastNames: userData.lastNames,
        province: userData.province,
        city: userData.city,
        address: userData.address,
        phone1: userData.phone1,
        phone2: userData.phone2,
        email: userData.email,
        category: userData.category,
        discount: userData.discount,
      }

      this.sex = this.user.sex;
      this.userEmail = this.user.email;

      this.userFormGroup['controls']['sex'].setValue(this.sex);

      this.userFormGroup['controls']['names'].setValue(userData.names);
      this.userFormGroup['controls']['lastNames'].setValue(userData.lastNames);
      this.userFormGroup['controls']['province'].setValue(userData.province);
      this.userFormGroup['controls']['city'].setValue(userData.city);

      this.userFormGroup['controls']['ruc'].setValue(userData.ruc);
      this.userFormGroup['controls']['birthday'].setValue(userData.birthday);

      this.userFormGroup['controls']['address'].setValue(userData.address);
      this.userFormGroup['controls']['phone1'].setValue(userData.phone1);
      this.userFormGroup['controls']['phone2'].setValue(userData.phone2);

      for(var i = 0; i < this.locations.length; i++){
        if(this.locations[i].provincia === this.user.province){
          this.citiesList = this.locations[i].cantones;
        }
      }
      this.isLoading = false;
    })

    this.statusCodeSub = this.editUserService.getStatusCodeListener()
      .subscribe((status) => {
        this.statusCode = status;

        if(this.statusCode === 200){
          this.openDialog();
        }
      })

  }

  getCities(index){
    this.citiesList = this.locations[index].cantones
  }

  onSaveUser(){
    if(this.userFormGroup.invalid){
      return
    }

    const user: User = {
      _id: null,
      ruc: this.userFormGroup.value.ruc,
      sex: this.userFormGroup.value.sex,
      birthday: this.userFormGroup.value.birthday,
      password: null,
      names: this.userFormGroup.value.names,
      lastNames: this.userFormGroup.value.lastNames,
      province: this.userFormGroup.value.province,
      city: this.userFormGroup.value.city,
      address: this.userFormGroup.value.address,
      phone1: this.userFormGroup.value.phone1,
      phone2: this.userFormGroup.value.phone2,
      email: null,
      category: null,
      discount: null,
    }
    this.editUserService.updateUser(this.userId, user);

  }

  onRestorePassword(){
    this.router.navigate(['./restablecer-contrasena/' + this.userEmail])
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogEditUserConfirmation);

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }
}
