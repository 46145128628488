<h2 mat-dialog-title>Cuenta existente</h2>
<div class="mat-divider-container">
  <mat-divider></mat-divider>
</div>
<mat-dialog-content class="mat-typography">
  <h5>El correo proporcionado ya se encuentra registrado.</h5>
  <h5>Registrese con un correo diferente o inicie sesión.</h5>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-raised-button color="accent" mat-dialog-close>Entendido</a>
</mat-dialog-actions>
