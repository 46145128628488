<!-- Footer -->
<footer class="page-footer font-small special-color-dark pt-4">
  <div class="container">
    <!-- Footer Elements -->

    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-md-6 mb-4">
        <div class="title container">
          <h4><strong>Contáctanos</strong></h4>
        </div>

        <div class="mat-divider-container">
          <mat-divider></mat-divider>
        </div>

        <form #contactForm="ngForm" (ngSubmit)="onContactForm(contactForm)">
          <mat-card class="form-field">
            <div>
              <mat-form-field>
                <input
                  matInput
                  ngModel
                  name="names"
                  type="text"
                  placeholder="Nombres"
                />
              </mat-form-field>
            </div>
          </mat-card>
          <mat-card class="form-field">
            <div>
              <mat-form-field>
                <input
                  matInput
                  ngModel
                  name="email"
                  type="email"
                  placeholder="Correo"
                />
              </mat-form-field>
            </div>
          </mat-card>
          <mat-card class="form-field">
            <div>
              <mat-form-field>
                <mat-label>Mensaje</mat-label>
                <textarea matInput ngModel name="message" rows="7"></textarea>
              </mat-form-field>
            </div>
          </mat-card>

          <div class="submit-button">
            <button mat-raised-button color="accent" type="submit">
              <strong>Enviar mensaje</strong>
            </button>
          </div>
        </form>

        <!--Grid column-->
      </div>

      <div class="col-md-6 mb-4">
        <div class="title container">
          <h4><strong>Acerca de nosotros</strong></h4>
        </div>
        <div class="mat-divider-container">
          <mat-divider></mat-divider>
        </div>

        <div class="title container">
          <h5>
            Somos una empresa ecuatoriana con 40 años de presencia en el
            mercado, dedicados a la producción, importación, comercialización y
            distribución de mini juguetes para fiestas infantiles, juguetes
            educativos y juguetes fabricados en madera.
          </h5>
          <h5>
            Nuestro principal objetivo es mantener la satisfacción de nuestros
            clientes con una variedad de productos de la mejor calidad y un
            excelente servicio.
          </h5>
        </div>

        <div class="follow container">
          <h4><strong>Síguenos</strong></h4>
        </div>

        <div class="mat-divider-container">
          <mat-divider></mat-divider>
        </div>

        <div class="container">
          <a
            href="https://www.facebook.com/ImportacionesBalloon/"
            target="_blank"
            ><i class="fa fa-facebook-square" aria-hidden="true"></i
          ></a>
          <a
            href="https://www.instagram.com/balloon.importaciones/?hl=es-la"
            target="_blank"
            ><i class="fa fa-instagram" aria-hidden="true"></i
          ></a>
          <a
            href="https://www.youtube.com/channel/UCnBU3ECJlvYei681CphbLqw"
            target="_blank"
            ><i class="fa fa-youtube-play" aria-hidden="true"></i
          ></a>
        </div>

        <div class="follow container">
          <h4>
            <strong>
              <span class="text-white">Conoce Nuestras </span>
              <a routerLink="/privacidad" class="privacy-link"
                >Políticas de Privacidad</a
              >
            </strong>
          </h4>
        </div>
        <div class="mat-divider-container">
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Elements -->
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    © 2024 Copyright: BALLOON | Powered by: Ekolabs
    <!-- Copyright -->
  </div>
</footer>

<!-- Footer -->
