<div class="container">
  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <h4>Nuestras Líneas</h4>
    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div class="row">

      <div class="col-lg-6"  *ngFor="let category of productsCategories; let i = index">
          <a routerLink="productos/{{category.name}}/{{category._id}}">
            <div class="example-ripple-container mat-elevation-z4"
                 matRipple
                 [matRippleDisabled]="false"
                 [matRippleColor]="'rgba(80,124,255, 0.5)'" >
              <div class="category-title">
                <h3><strong>{{category.name}}</strong></h3>
              </div>
              <div>
                <img width="100%" src="{{category.image1Path}}">
              </div>
            </div>
          </a>
      </div>
    </div>
  </mat-card>
</div>
