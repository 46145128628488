import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {CarItem} from "./car-item.model";
import {ShoppingCar} from "./shopping-car.model";
import {AuthClientService} from "../auth/auth.service";
import {environment} from "../../environments/environment";
import {ProformaService} from "../proforma/proforma.service";

@Injectable({providedIn: "root"})
export class ShoppingCarService {
  private shoppingCar: ShoppingCar;
  private shoppingCarUpdated = new Subject<{shoppingCar: ShoppingCar, subsubtotal: number, subtotal: number, discount: number,
                                            taxes: number, iva: number, total: number}>();
  public subtotal: number;
  public subsubtotal: number;
  public discount = 0;
  public taxes: number;
  public iva = 0;
  public total: number;

  statusCode: number;
  statusCodeListener = new Subject<number>()

  ADD_ITEM_URL = environment.apiUrl + '/shopping-car/add-item/';
  ADD_ITEMS_URL = environment.apiUrl + '/shopping-car/add-items/';
  SHOPPING_CAR_URL = environment.apiUrl + '/shopping-car/';
  DELETE_ITEM_URL = environment.apiUrl + '/shopping-car/delete-item';
  PLACE_ORDER_URL = environment.apiUrl + '/shopping-car/place-order';
  constructor(private http: HttpClient,
              private router: Router,
              private authService: AuthClientService,
              private proformaService: ProformaService) {}

  getStatusCodeListener(){
    return this.statusCodeListener.asObservable();
  }

  getShoppingCarUpdateListener(){
    return this.shoppingCarUpdated.asObservable();
  }

  transferProformaToShoppingCar(userId: string, items: CarItem[]){
  }

  addItemsToShoppingCar(userId: string, itemList: CarItem[]){
    const queryParams = `?userId=${userId}`;
    this.http.post<{message: string, shoppingCar: ShoppingCar, status: number}>(this.ADD_ITEMS_URL + userId + queryParams, itemList)
      .subscribe((responseData) => {
        if(responseData.status === 200 || responseData.status === 201){
          this.proformaService.clearProformaData()
          this.router.navigate(['/carro-compras'])
        }

      })
  }


  addItemToShoppingCar(userId: string, item: CarItem){
    const queryParams = `?userId=${userId}&productId=${item.productId}`;
    this.http.post<{message: string, shoppingCar: ShoppingCar, subtotal: number}>(this.ADD_ITEM_URL + userId + queryParams, item)
      .subscribe((responseData) => {
        // console.log(responseData.message);
        this.shoppingCar = responseData.shoppingCar;
        this.subtotal = responseData.subtotal;
        this.iva = responseData.shoppingCar['iva'];
        if(responseData.shoppingCar['userDiscount'] > 0){
          this.discount = this.subtotal * (responseData.shoppingCar['userDiscount']) * 0.01
        }

        this.subsubtotal = (this.subtotal - this.discount)
        this.taxes = this.subsubtotal * this.iva * 0.01
        this.total = (this.subtotal - this.discount) + this.taxes

        this.shoppingCarUpdated.next({
          shoppingCar: responseData.shoppingCar,
          subtotal: responseData.subtotal,
          subsubtotal: this.subsubtotal,
          discount: this.discount,
          taxes: this.taxes,
          iva: this.iva,
          total: this.total
        })
        this.router.navigate(['/carro-compras']);
      })
  }

  getShoppingCar(userId: string){
    const queryParams = `?userId=${userId}`;
    this.http.get<{message: string, shoppingCar: ShoppingCar, subtotal: number}>(this.SHOPPING_CAR_URL + userId + queryParams)
      .subscribe((responseData) => {
        // console.log(responseData.message);
        if(!responseData.shoppingCar){
          this.shoppingCarUpdated.next({
            subsubtotal: null,
            shoppingCar: null,
            subtotal: null,
            discount: null,
            taxes: null,
            iva: null,
            total: null
          })
          return
        }
        this.shoppingCar = responseData.shoppingCar;
        this.subtotal = responseData.subtotal;
        this.iva = responseData.shoppingCar['iva'];
        if(responseData.shoppingCar['userDiscount'] > 0){
          this.discount = this.subtotal * (responseData.shoppingCar['userDiscount']) * 0.01
        }
        this.subsubtotal = (this.subtotal - this.discount)
        this.taxes = (this.subtotal - this.discount) * this.iva * 0.01
        this.total = (this.subtotal - this.discount) + this.taxes
        this.shoppingCarUpdated.next({
          shoppingCar: responseData.shoppingCar,
          subtotal: responseData.subtotal,
          subsubtotal: this.subsubtotal,
          discount: this.discount,
          taxes: this.taxes,
          iva: this.iva,
          total: this.total
        })
      })
  }

  deleteItem(index: number, itemId: string){
    const userId = this.authService.getUserId()
    const queryParams = `?shoppingCarId=${this.shoppingCar._id}&itemId=${itemId}&userId${userId}`
    this.http.delete<{message: string, shoppingCar: ShoppingCar, subtotal: number}>(this.DELETE_ITEM_URL + queryParams)
      .subscribe((responseData) => {
        // console.log(responseData.message);
        if(!responseData.shoppingCar){
          this.shoppingCarUpdated.next({
            subsubtotal: null,
            shoppingCar: null,
            subtotal: null,
            discount: null,
            taxes: null,
            iva: null,
            total: null
          })
          return
        }

        this.shoppingCar = responseData.shoppingCar;

        this.subtotal = responseData.subtotal;
        this.iva = responseData.shoppingCar['iva'];
        if(responseData.shoppingCar['userDiscount'] > 0){
          this.discount = this.subtotal * (responseData.shoppingCar['userDiscount']) * 0.01
        }

        this.subsubtotal = (this.subtotal - this.discount)
        this.taxes = (this.subtotal - this.discount) * this.iva * 0.01
        this.total = (this.subtotal - this.discount) + this.taxes
        this.shoppingCarUpdated.next({
          shoppingCar: responseData.shoppingCar,
          subtotal: responseData.subtotal,
          subsubtotal: this.subsubtotal,
          discount: this.discount,
          taxes: this.taxes,
          iva: this.iva,
          total: this.total
        })

      })
  }

  placeOrder(carId: string, extraInformation: string){
    const userId = this.authService.getUserId()
    const queryParams = `?shoppingCarId=${carId}&userId${userId}`
    let date = new Date().getTimezoneOffset()
    this.http.post<{message: string}>(this.PLACE_ORDER_URL, {carId: carId, date: date, extraInformation: extraInformation})
      .subscribe((response) => {
        // console.log(response.message)
        this.statusCode = 200
        this.statusCodeListener.next(this.statusCode)
      }, error => {
        // console.log(error)
        this.statusCode = error.status;
        this.statusCodeListener.next(this.statusCode)
      })
  }


}
