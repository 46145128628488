import {Component, OnInit} from "@angular/core";
import {AuthClientService} from "../../auth/auth.service";
import {CategoryService} from "../../../../../../src/app/category/category.service";
import {Category} from "../../../../../../src/app/category/category.model";
import {Subscription} from "rxjs";

const dataTransform = (data) => {
  let newData = {};
  for (let i = 0; i < data.length; i++){
    newData[data[i]._id] = data[i]
  }
  return newData
}


@Component({
  selector: 'categories-menu-component',
  templateUrl: './categories-menu.component.html',
  styleUrls: ['./categories-menu.component.css'],
})

export class CategoriesMenuComponent implements OnInit{
  productsCategories: Category[];
  newProductsCategories: {};
  private categoriesSub: Subscription;

  constructor( private categoriesService: CategoryService) {}

  isLoading: boolean;
  ngOnInit() {
    this.isLoading = true;


    this.categoriesService.getCategories()
    this.categoriesSub = this.categoriesService.getCategoryUpdateListener()
      .subscribe((categoriesData: {categories: Category[]}) => {
        this.isLoading = false;
        this.productsCategories = categoriesData.categories.filter(c => c.available);
        this.newProductsCategories = dataTransform(this.productsCategories)
      });
  }
}
