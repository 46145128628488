import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({providedIn: "root"})
export class HeaderServices {
  productsList = []
  productsListListener = new Subject<{ productsList: []}>()
  PRODUCT_LIST_URL = environment.apiUrl + '/other-services/get-products-list';
  constructor(private http: HttpClient) {}

  getProductsListListener(){
    return this.productsListListener.asObservable();
  }

  getListOfProductsToSearch(){
    this.http.get<{productsList: []}>(this.PRODUCT_LIST_URL)
      .subscribe((respData) => {
        this.productsList = respData.productsList;
        this.productsListListener.next({
          productsList: respData.productsList
        })
      })
  }
}
