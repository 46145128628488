import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-existing-email.html',
  styleUrls: ['dialog-existing-email.css']
})
export class DialogExistingEmail {

}
