<div class="container">
  <mat-spinner
    color="accent"
    style="margin: auto"
    *ngIf="isLoading"
  ></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <h4>Productos más vendidos</h4>
    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
      data-interval="3800"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>
      <div class="carousel-inner" *ngIf="products">
        <div class="carousel-item active">
          <!--          <a routerLink="/productos/{{categoriesNames[0]}}/{{products[0]?.category}}/{{products[0]?._id}}?starBar">-->
          <a
            routerLink="/productos/{{ categoriesNames[0] }}/{{
              products[0]?.category
            }}"
            [queryParams]="{ productId: products[0]?._id }"
          >
            <div class="carousel-caption d-none d-md-block">
              <h3>
                <strong>{{ products[0].name }}</strong>
              </h3>
            </div>
            <div>
              <img
                class="d-block"
                style="margin: 35px auto; width: 450px"
                [src]="products[0]?.image1Path"
                alt="First slide"
              />
            </div>
          </a>
        </div>

        <div class="carousel-item">
          <a
            routerLink="/productos/{{ categoriesNames[1] }}/{{
              products[1]?.category
            }}"
            [queryParams]="{ productId: products[1]?._id }"
          >
            <div class="carousel-caption d-none d-md-block">
              <h3>
                <strong>{{ products[1]?.name }}</strong>
              </h3>
            </div>
            <div>
              <img
                class="d-block"
                style="margin: 35px auto; width: 450px"
                [src]="products[1]?.image1Path"
                alt="Second slide"
              />
            </div>
          </a>
        </div>

        <div class="carousel-item">
          <a
            routerLink="/productos/{{ categoriesNames[2] }}/{{
              products[2]?.category
            }}"
            [queryParams]="{ productId: products[2]?._id }"
          >
            <div class="carousel-caption d-none d-md-block">
              <h3>
                <strong>{{ products[2]?.name }}</strong>
              </h3>
            </div>
            <div>
              <img
                class="d-block"
                style="margin: 35px auto; width: 450px"
                [src]="products[2]?.image1Path"
                alt="Third slide"
              />
            </div>
          </a>
        </div>

        <div class="carousel-item">
          <a
            routerLink="/productos/{{ categoriesNames[3] }}/{{
              products[3]?.category
            }}"
            [queryParams]="{ productId: products[3]?._id }"
          >
            <div class="carousel-caption d-none d-md-block">
              <h3>
                <strong>{{ products[3]?.name }}</strong>
              </h3>
            </div>
            <div>
              <img
                class="d-block"
                style="margin: 35px auto; width: 450px"
                [src]="products[3]?.image1Path"
                alt="Third slide"
              />
            </div>
          </a>
        </div>

        <div class="carousel-item">
          <a
            routerLink="/productos/{{ categoriesNames[4] }}/{{
              products[4]?.category
            }}"
            [queryParams]="{ productId: products[4]?._id }"
          >
            <div class="carousel-caption d-none d-md-block">
              <h3>
                <strong>{{ products[4]?.name }}</strong>
              </h3>
            </div>
            <div>
              <img
                class="d-block"
                style="margin: 35px auto; width: 450px"
                [src]="products[4]?.image1Path"
                alt="Third slide"
              />
            </div>
          </a>
        </div>
      </div>

      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>
  </mat-card>
</div>
