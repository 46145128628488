<div class="container">

  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <div class="block">
      <h3><strong>RESTABLECER CONTRASEÑA</strong></h3>
    </div>

    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div class="block">
      <form (submit)="onRecoverPassword(loginForm)" #loginForm="ngForm">
        <div class="grid-container">
          <div class="center-icon">
            <mat-icon>email</mat-icon>
          </div>

          <div>
            <mat-form-field >
              <input matInput
                     [disabled]="isLogged"
                     required
                     name="email"
                     [(ngModel)] = "userEmail"
                     type="email"
                     placeholder="Correo electrónico" >
            </mat-form-field>
          </div>

        </div>

        <div class="alert-danger" *ngIf="statusCode === 400"><h6>Cuenta no encontrada. Ingrese nuevamente.</h6></div>


        <div class="block">
          <button mat-raised-button type="submit" color="accent"><strong>Restablecer contraseña</strong></button>
        </div>

      </form>
    </div>

    <div class="block" *ngIf="!isLogged">
      ¿No eres miembro? <a routerLink="/registro">¡Regístrate!</a>
    </div>

  </mat-card>


</div>

