import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: "root"})
export class PasswordRecoverService {
  isRequestValid: boolean;
  requestValidationListener =  new Subject<{ isValid: boolean }>()
  statusCode: number;
  statusCodeListener = new Subject<number>()
  RECOVER_PASSW_URL = environment.apiUrl + '/usuarios/restablecer-contrasena';
  CONFIRM_REQUEST_URL = environment.apiUrl + '/usuarios/resetear-contrasena';

  constructor(private http: HttpClient, private router: Router) {}


  recoverPassword(userEmail: string){
    const queryParams = `?userEmail=${userEmail}`;

    this.http.put<{message: string}>(this.RECOVER_PASSW_URL + queryParams, {userEmail: userEmail})
      .subscribe((responseData) => {
        // console.log(responseData.message);
        this.statusCode = 200;
        this.statusCodeListener.next(this.statusCode)
    }, (error) => {
        this.statusCode = error.status;
        this.statusCodeListener.next(this.statusCode)
      })
  }

  getIsRequestValidationListener(){
    return this.requestValidationListener.asObservable()
  }

  getStatusCodeListener(){
    return this.statusCodeListener.asObservable();
  }

  getIsrequestValid(){
    return this.isRequestValid;
  }


  confirmRequest(token: string){
    this.http.get<{message: string, validRequest: boolean}>(this.CONFIRM_REQUEST_URL + '/'+ token)
      .subscribe(
        data => {
          // console.log(data.message)
          this.isRequestValid = data.validRequest;
          this.requestValidationListener.next({isValid: this.isRequestValid})
        },
          error => {
            this.isRequestValid = false;
            this.requestValidationListener.next({isValid: this.isRequestValid})
          }
      )
  }


  resetPassword(password: string, token: string){
    this.http.put<{message: string}>(this.CONFIRM_REQUEST_URL, {token: token, password: password})
      .subscribe((response) => {
        // console.log(response.message)

        this.statusCode = 200;
        this.statusCodeListener.next(this.statusCode)

      }, error => {
        this.statusCode = error.status;
        this.statusCodeListener.next(this.statusCode)
      })
  }


}
