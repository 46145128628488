<h2 mat-dialog-title>Mensaje enviado</h2>
<div class="mat-divider-container">
  <mat-divider></mat-divider>
</div>
<mat-dialog-content class="mat-typography">
  <h5>En breve nos comunicaremos con usted.</h5>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-raised-button color="accent" mat-dialog-close>Aceptar</a>
</mat-dialog-actions>
