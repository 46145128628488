import { Product } from "../../../../../src/app/products/product.model";
import { Injectable } from "@angular/core";
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class CurrentPageService {
  private currentPage = new Subject<{ items: any }>();

  constructor() {
  }

  public saveCurrentPage(currentPage: number) {
    localStorage.setItem('currentPage', currentPage.toString())
  }

  public clearCurrentPage() {
    localStorage.removeItem('currentPage')
  }

  public getCurrentPage() {
    return localStorage.getItem('currentPage')
  }
}





