<div class="container">

  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <div class="block">
      <h3><strong>INGRESAR</strong></h3>
    </div>

    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div class="block">
      <form (submit)="onLogin(loginForm)" #loginForm="ngForm">
        <div class="grid-container">
          <div class="center-icon">
            <mat-icon>email</mat-icon>
          </div>

          <div>
            <mat-form-field>
              <input matInput  required name="username" ngModel type="email" placeholder="Correo electrónico" >
            </mat-form-field>
          </div>

        </div>

        <div class="grid-container">
          <div class="center-icon">
            <mat-icon>lock</mat-icon>
          </div>
          <div>
            <mat-form-field>
              <input matInput  required name="password" ngModel type="password" placeholder="Contraseña" >
            </mat-form-field>
          </div>
        </div>

        <div class="alert-danger" *ngIf="statusCode === 400"><h6>Credenciales incorrectas. Ingrese nuevamente.</h6></div>


        <div class="block">
          <button mat-raised-button type="submit" color="accent"><strong>Ingresar</strong></button>
        </div>

      </form>
    </div>


    <div class="block">
      ¿No eres miembro? <a routerLink="/registro">¡Regístrate!</a>
    </div>
    <div class="block-forgow-pssw">
      <a routerLink="/restablecer-contrasena">¿Olvidaste tu constraseña?</a>
    </div>
  </mat-card>


</div>

