import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-password-updated.service.html',
  styleUrls: ['dialog-password-updated.service.css']
})
export class DialogPasswordUpdated {

}
