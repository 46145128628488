import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Contact} from "./contact.model";
import {Subject, Subscription} from "rxjs";
import {ShoppingCar} from "../shopping-car/shopping-car.model";
import {AuthClientService} from "../auth/auth.service";
import {environment} from "../../environments/environment";

@Injectable({providedIn: "root"})
export class ContactFormService {
  sentContactForm = false;
  contactFormSentListener = new Subject<{confirmation: boolean}>()
  statusCode: number;
  statusCodeListener = new Subject<number>()
  CONTACT_URL =environment.apiUrl + '/other-services/contact-request';
  constructor(private http: HttpClient, private authService: AuthClientService) {}

  getStatusCodeListener(){
    return this.statusCodeListener.asObservable();
  }

  getContactFormSentListener(){
    return this.contactFormSentListener.asObservable();
  }

  sendContactForm(contact: Contact){
    const userId = this.authService.getUserId()
    const queryParams = `?userId=${userId}`;
    this.http.post<{confirmation: boolean}>(this.CONTACT_URL + queryParams, contact)
      .subscribe((resp) => {
        this.statusCode = 200;
        this.statusCodeListener.next(this.statusCode)
      }, error =>  {
        this.statusCode = error.status;
        this.statusCodeListener.next(this.statusCode)
      })
  }

}
