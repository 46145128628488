import {User} from "../../../../../src/app/users/clients/user.model";
import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthClientData} from "./auth-data-client.model";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class AuthClientService {
  private token: string;
  private tokenTimer: number | undefined;
  private authStatusListener = new Subject<boolean>();
  private codeStatusListener = new Subject<number>();
  public username: string;
  private isLogged = false;
  public userId: string;
  private discount: string;

  private isActivated = false;

  private statusCode: number;

  LOGIN_URL = environment.apiUrl + '/usuarios/login';
  ACTIVATE_ACCOUNT_URL = environment.apiUrl + '/usuarios/activate';
  REGISTRAR_URL = environment.apiUrl + '/usuarios/registrar';
  constructor(private httpClient: HttpClient, private router: Router) {}


  activateAccount(token){
    this.httpClient.put<{message: string}>(this.ACTIVATE_ACCOUNT_URL, {temporaryToken: token})
      .subscribe((responseData) => {
        // console.log(responseData.message);
        this.statusCode = 200;
        this.codeStatusListener.next(this.statusCode)
      }, (error) => {
        this.statusCode = error.status;
        this.codeStatusListener.next(this.statusCode)
      })
  }

  signUpUser(user) {
    const queryParams = `?userMail=${user.email}`;
    this.httpClient.post<{ message: string, user: User}>(this.REGISTRAR_URL + queryParams, user)
      .subscribe((responseData) => {
        // console.log(responseData.message);
        this.statusCode = 200;
        this.codeStatusListener.next(this.statusCode)
      }, (error) => {
        // console.log(error)
        this.statusCode = error.status;
        this.codeStatusListener.next(this.statusCode)
      })
    this.router.navigate(['login']);
  }

  getUserId(){
    return this.userId;
  }

  getStatusCode(){
    return this.statusCode;
  }


  getToken(){
    return this.token;
  }

  getIsLogged(){
    return this.isLogged;
  }

  getIsActivated(){
    return this.isActivated;
  }

  getUsername(){
    return this.username;
  }

  getAuthStatusListener(){
    return this.authStatusListener.asObservable();
  }

  getCodeStatusListener(){
    return this.codeStatusListener.asObservable();
  }

  loginUser(mail: string, password: string){
    const queryParams = `?userMail=${mail}`;
    const authData: AuthClientData = {email: mail, password: password};
    this.httpClient.post<{userId: string, name: string, token: string, expiresIn: number, status: number, discount: string}>
    (this.LOGIN_URL + queryParams, authData)
      .subscribe(response => {
        this.token = response.token;
        if(response.token){
          this.statusCode = response.status;
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.username = response.name;
          this.isLogged = true;
          this.userId = response.userId;
          this.discount = response.discount;

          this.authStatusListener.next(true);
          this.codeStatusListener.next(this.statusCode);

          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(this.token, expirationDate, this.username, this.userId, this.discount);
          this.router.navigate(['']);
        }
      }, error => {
        // console.log(error)

        if(error.status === 401 || error.status === 403 || error.status === 400){
          this.statusCode = error.status
          this.codeStatusListener.next(this.statusCode);
        }
      });
  }

  autoAuthUser(){
    const authInformation = this.getAuthData();

    if(!authInformation){
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if(expiresIn > 0){
      this.token = authInformation.token;
      this.username = authInformation.username;
      this.userId = authInformation.userId;
      this.isLogged = true;
      this.setAuthTimer(expiresIn / 1000);

      this.authStatusListener.next(true);
    }
  }

  checkUserIdFromLocalStorage(){
    return localStorage.getItem('userId');
  }

  logOutUser(){
    this.token = null;
    this.isLogged = false;
    this.authStatusListener.next(false);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number){
    this.tokenTimer = window.setTimeout(()=>{
      this.logOutUser();
    }, duration * 1000)
  }


  private saveAuthData(token: string, expirationDate: Date, username: string, userId: string, discount: string){
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('username', username);
    localStorage.setItem('userId', userId);
    localStorage.setItem('discount', discount);
  }

  private clearAuthData(){
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('discount');

  }

  public getUserBasicInfo(){
    const discount = localStorage.getItem('discount');
    const userId = localStorage.getItem('userId');
    if(!discount){
      return;
    }
    return {
      discount: parseInt(discount),
      userId: userId
    }
  }

  private  getAuthData(){
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const username = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');

    if(!token || !expirationDate || !username || !userId){
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      username: username,
      userId: userId,
    }
  }



}
