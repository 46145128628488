<h2 mat-dialog-title>Orden recibida</h2>
<div class="mat-divider-container">
  <mat-divider></mat-divider>
</div>
<mat-dialog-content class="mat-typography">
  <h5>Hemos recibido su pedido. Una copia de su orden ha sido</h5>
  <h5>enviada a su correo. En breve nos comunicaremos con usted.</h5>
  <h5>Verfique su carpeta de correo no deseado.</h5>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-raised-button color="accent" mat-dialog-close>Aceptar</a>
</mat-dialog-actions>

