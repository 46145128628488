import {Component, OnInit} from '@angular/core';
import {AuthClientService} from "./auth/auth.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'store';
  isClientAuthenticated = false;
  authListenerSubs: Subscription;

  constructor(private authClientService: AuthClientService, private router: Router) {}

  ngOnInit() {
    this.authClientService.autoAuthUser();
    this.isClientAuthenticated = this.authClientService.getIsLogged();

    this.authListenerSubs = this.authClientService.getAuthStatusListener().subscribe((isAuthenticated) => {
      this.isClientAuthenticated = isAuthenticated;

    })




  }





}
