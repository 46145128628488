<mat-spinner
  color="accent"
  style="margin: auto"
  *ngIf="isLoading"
></mat-spinner>

<div class="container">
  <section class="mat-typography title-group">
    <span class="title-group">
      <a mat-button routerLink="/" routerLinkActive="mat-accent"
        ><h2>Home</h2></a
      ></span
    >
    <span class="spacer"></span>
    <mat-divider></mat-divider>
  </section>

  <div class="grid-item prices-info-container">
    <mat-card>
      <div>
        <h6>Valor: $ {{ subtotal }}</h6>
      </div>
      <div *ngIf="userDiscount > 0">
        <h6>{{ userDiscount }}% Desc. : $ {{ discount }}</h6>
      </div>
      <div>
        <h6>Subtotal: $ {{ subsubtotal }}</h6>
      </div>
      <div>
        <h6>{{ iva }}% I.V.A. : $ {{ taxes }}</h6>
      </div>
      <div>
        <h5>Total: $ {{ total }}</h5>
      </div>
      <div>
        <button (click)="onSaveProforma()" mat-raised-button color="accent">
          Guardar proforma en carrito
        </button>
      </div>
    </mat-card>
    <!-- <div *ngIf="total < minimumPurchaseValue" style="color: orangered">
      {{politicsMessage}}
    </div> -->
  </div>

  <mat-card *ngFor="let p of proformaTable; let i = index">
    <div class="description">
      <div class="characteristics">
        <h4>{{ p.category }}</h4>
      </div>
      <div class="mat-divider-container">
        <mat-divider></mat-divider>
      </div>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="p?.items" class="mat-elevation-z2">
        <!-- Image Column -->
        <ng-container matColumnDef="imageUrl">
          <th mat-header-cell *matHeaderCellDef>Imagen</th>
          <td mat-cell *matCellDef="let element">
            <div class="img-thumbnail">
              <img [src]="element.imageUrl" />
            </div>
          </td>
        </ng-container>

        <!-- Reference Column -->
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>Referencia</th>
          <td mat-cell *matCellDef="let element">{{ element?.reference }}</td>
        </ng-container>

        <!-- BarCode Column -->
        <ng-container matColumnDef="barCode">
          <th mat-header-cell *matHeaderCellDef>Código barras</th>
          <td mat-cell *matCellDef="let element">{{ element?.barCode }}</td>
        </ng-container>

        <!-- Presentation Column -->
        <ng-container matColumnDef="presentation">
          <th mat-header-cell *matHeaderCellDef>Presentación</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.presentation }}
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Precio</th>
          <td mat-cell *matCellDef="let element">$ {{ element?.price }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="fill">
              <mat-label>Cantidad a adquirir</mat-label>
              <label>
                <input
                  (change)="onInputChange($event)"
                  [value]="element?.quantity"
                  id="{{ element.reference }}"
                  matInput
                  type="number"
                  min="0"
                />
              </label>
            </mat-form-field>

            <div class="row-field-container">
              <button
                (click)="onDeleteItem(element.reference)"
                class="button"
                mat-fab
                color="primary"
                aria-label="Example icon button with a delete icon"
              >
                <mat-icon>delete</mat-icon>
              </button>

              <a
                routerLink="/productos/{{ element.category }}/{{
                  element.categoryId
                }}/{{ element.productId }}"
              >
                <button
                  mat-fab
                  color="accent"
                  aria-label="Example icon button with a bookmark icon"
                >
                  <mat-icon>pageview</mat-icon>
                </button>
              </a>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card>
</div>
