<div class="container">

  <mat-spinner color="accent" style="margin: auto" *ngIf="isLoading"></mat-spinner>

  <mat-card *ngIf="!isLoading">
    <div class="block">
      <h3><strong>{{title}}</strong></h3>
    </div>

    <div class="mat-divider-container">
      <mat-divider></mat-divider>
    </div>

    <div class="block">

      <div>
        <h6>{{body}}</h6>
      </div>

        <div class="block">
          <a mat-raised-button routerLink="/login" type="submit" color="accent"><strong>Inicia sesión</strong></a>
        </div>

    </div>

  </mat-card>


</div>

