import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {PasswordRecoverService} from "../password-recover.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CustomValidationService} from "../../custom-validation.service";
import {AuthClientService} from "../../auth.service";
import {Subscription} from "rxjs";
import {DialogPasswordUpdated} from "../dialogs/password-updated/dialog-password-updated.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'password-recover-component',
  templateUrl: 'change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy{
  constructor(private recoverPasswordService: PasswordRecoverService, private router: Router,
              private formGroup: FormBuilder, private passwordValidator: CustomValidationService,
              private authService: AuthClientService, private activeRoute: ActivatedRoute,
              private dialog: MatDialog) { }

  isLoading:boolean;
  isRequestValid: boolean;
  isLogged: boolean;
  passForm: FormGroup;
  token: string;
  statusCode: number;
  statusCodeSub: Subscription;

  isRequestValidSub: Subscription;

  ngOnDestroy() {
    this.statusCodeSub.unsubscribe();
    this.isRequestValidSub.unsubscribe();
  }

  ngOnInit() {
    window.scroll(0, 0)
    this.isLoading = true;

    this.isLogged = this.authService.getIsLogged();

    this.passForm = this.formGroup.group({
      password: ['', [Validators.required], []],
      passwordValidation: ['', [Validators.required], []]
    }, {
      validator: [this.passwordValidator.passwordValidator("password"),
        this.passwordValidator.passwordMatchValidator("password", "passwordValidation"),
      ]},
    )


    this.activeRoute.paramMap.subscribe((paramMap) => {
      if(paramMap.has('token')){
        this.token = paramMap.get('token')
        this.recoverPasswordService.confirmRequest(this.token)

        this.isRequestValidSub = this.recoverPasswordService.getIsRequestValidationListener().subscribe((respData) => {
          this.isRequestValid = respData.isValid;

          if(!this.isRequestValid){
            this.router.navigate(['/login'])
          }

        })

      }
    })

    this.statusCodeSub = this.recoverPasswordService.getStatusCodeListener()
      .subscribe((status) => {
        this.statusCode = status;

        if(this.statusCode === 200){
          this.openDialog();
        }
      })


    this.isLoading = false;

  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogPasswordUpdated);
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }



  onChangePassword(){
    if(this.passForm.invalid){
      return
    }
    this.recoverPasswordService.resetPassword(this.passForm.value.password, this.token)
  }
}
